import { useTranslation } from 'react-i18next';

const Banner = () => {
    const { t } = useTranslation()

    return (
        <div className="bannerProductBook">
            <h5 className="col-7 text-orange fw-bold fs-42 m-fs-24 lh-130 border-white-text">{t('products.book.title')}</h5> <br />
            <h6 className="col-6 white-text fw-500 fs-22 m-fs-16 lh-160">{t('products.book.description')}</h6>
            <a className="text-decoration" href="https://api.whatsapp.com/send?phone=628111451220" target="_blank" rel="noreferrer">
                <button className="btnAppEurekaFill py-1 text-white fw-bold m-fs-12">{t('products.book.orderButton')}</button>
            </a>
        </div>
    )
}

export default Banner