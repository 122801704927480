import Content2 from './Content-2'
import { ReactComponent as WhatsappLogo } from '../../assets/images/Whatsapp.svg'
import Email from '../../assets/images/Email.svg'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'

const Maps = () => {
    const defaultPosition = [-6.291523789033755, 106.79549192470711]
    
    return(
        <div>
            <div className="row mt-3">
                <div className="col-lg-4 content1Contact">
                    <div>
                        <MapContainer
                            center={defaultPosition}
                            zoom={13}
                            scrollWheelZoom={true}
                        >
                            <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={defaultPosition}></Marker>
                        </MapContainer>
                    </div>
                    <h6 className='fw-bold fs-16 m-fs-12 lh-20 mt-4 dark-text'>Jalan Fatmawati, Gedung Graha Satria <br /> 1 Suite 203, Cilandak Barat, Jakarta <br /> 12430, ID</h6>
                    {/* Whatsapp */}
                    <div className="d-flex flex-row mb-2 mt-4">
                        <a className="text-decoration" href="https://api.whatsapp.com/send?phone=628111451220" target="_blank" rel="noreferrer">
                            <WhatsappLogo className="d-inline-block" />
                            <h6 className="fs-16 m-fs-12 lh-20 fw-bold text-grey ms-2 text-blue d-inline-block">08111451220</h6>
                        </a>
                    </div>
                    {/* Email */}
                    <div className="d-flex flex-row mt-3">
                    <a className="text-decoration" href="https://mail.google.com/mail/?view=cm&fs=1&to=info@eurekaedutech.com" target="_blank" rel="noreferrer">
                        <img src={ Email } alt="Eureka" />
                        <h6 className="fs-16 m-fs-12 lh-20 fw-bold text-grey ms-2 text-blue d-inline-block">info@eurekaedutech.com</h6>
                    </a>
                    </div>
                </div>
                <div className="col-lg-7">
                    <Content2 />
                </div>
            </div>
        </div>
    )
}

export default Maps