import { Trans, useTranslation } from "react-i18next"

const Content3 = () => {
    const { t } = useTranslation()

    return(
        <div className="content3Policy mt-4">
            <div className="fs-16 m-fs-12 lh-160 fw-500 text-grey">
                <b className="m-fs-12">Eureka Edutech </b>{t('policy.paragraph1')}
                    <br />
                    <br />
                    {t('policy.paragraph2')}
                    <br />
                    <br />
                    <br />
                <b className="fs-20 m-fs-14">{t('policy.info1.title')}</b> 
                    <br />
                    <br />
                <Trans 
                    i18nKey='policy.info1.paragraph1'
                    components={[<strong></strong>]}
                >
                </Trans>
                    <br />
                    <br />
                <Trans 
                    i18nKey='policy.info1.paragraph2'
                    components={[<strong></strong>]}
                ></Trans>
                    <br />
                    <br />
                    <br />
                <b className="fs-20 m-fs-14">{t('policy.info2.title')}</b>
                    <br />
                    <br />
                <Trans 
                    i18nKey='policy.info2.paragraph1'
                    components={[<strong></strong>]}
                ></Trans>
                    <br />
                    <br />
                <Trans 
                    i18nKey='policy.info2.paragraph2'
                    components={[<strong></strong>]}
                ></Trans>
                    <br />
                    <br />
                <Trans 
                    i18nKey='policy.info2.paragraph3'
                    components={[<strong></strong>]}
                ></Trans>
                    <br />
                    <br />
                <Trans 
                    i18nKey='policy.info2.paragraph4'
                    components={[<strong></strong>]}
                ></Trans>
                    <br />
                    <br />
                    <br />
                <b className="fs-20 m-fs-14">{t('policy.info3.title')}</b>
                    <br />
                    <br />
                <Trans 
                    i18nKey='policy.info3.paragraph1'
                    components={[<strong></strong>]}
                ></Trans>
                    <br />
                    <br />
                    <br />  
                <b className="fs-20 m-fs-14">{t('policy.info4.title')}</b>
                    <br />
                    <br />
                {t('policy.info4.paragraph1')}
                    <br />
                    <br />
                    <br />
                <b className="fs-20 m-fs-14">{t('policy.info5.title')}</b>
                <ul>
                    <li className="my-4 m-fs-12">{t('policy.info5.item1')}</li>
                    <li className="my-4 m-fs-12">{t('policy.info5.item2')}</li>
                    <li className="mt-4 m-fs-12">{t('policy.info5.item3')}</li>
                </ul>
                    <br />
                    <br />
                    <br />
                <b className="fs-20 m-fs-14">{t('policy.info6.title')}</b>
                    <br />
                    <br />
                {t('policy.info6.list.content')}
                <ul>
                    <li className="my-4 m-fs-12">
                        <Trans 
                            i18nKey='policy.info6.list.item1'
                            components={[<strong></strong>]}
                        ></Trans>
                    </li>
                    <li className="mt-4 m-fs-12">
                        <Trans 
                            i18nKey='policy.info6.list.item2'
                            components={[<strong></strong>]}
                        ></Trans>
                    </li>
                </ul>
                    <br />
                    <br />
                    <br />
                <b className="fs-20 m-fs-14">{t('policy.info7.title')}</b>
                    <br />
                    <br />
                {t('policy.info7.content')}
                    <br />
                    <br />
                <ul>
                    <li className="my-4 m-fs-12">
                        <Trans 
                            i18nKey='policy.info7.list.item1'
                            components={[<strong></strong>]}
                        ></Trans>
                    </li>
                    <li className="mt-4 m-fs-12">
                        <Trans 
                            i18nKey='policy.info7.list.item2'
                            components={[<strong></strong>]}
                        ></Trans>
                    </li>
                </ul>
                    <br />
                    <br />
                    <br />
                <b className="fs-20 m-fs-14">{t('policy.info8.title')}</b>
                    <br />
                    <br />
                <b className="m-fs-12">A. {t('policy.info8.list1.title')}</b>
                    <br />
                {t('policy.info8.list1.content')}
                    <br />
                    <br />
                <b className="m-fs-12">B. {t('policy.info8.list2.title')}</b>
                    <br />
                {t('policy.info8.list2.content')}
                    <br />
                    <br />
                <b className="m-fs-12">C. {t('policy.info8.list3.title')}</b>
                    <br />
                {t('policy.info8.list3.content')}
                    <br />
                    <br />
                <b className="m-fs-12">D. {t('policy.info8.list4.title')}</b>
                    <br />
                {t('policy.info8.list4.content')}
                    <br />
                    <br />
                    <br />
                <b className="fs-20 m-fs-14">{t('policy.info9.title')}</b>
                    <br />
                    <br />
                {t('policy.info9.list.content')}
                <ul>
                    <li className="my-4 m-fs-12">{t('policy.info9.list.item1')}</li>
                    <li className="mt-4 m-fs-12">{t('policy.info9.list.item2')}</li>
                </ul>
                    <br />
                    <br />
                    <br />
                <b className="fs-20 m-fs-14">{t('policy.info10.title')}</b>
                    <br />
                    <br />
                {t('policy.info10.content')}
                    <br />
                    <br />
                    <br />
                <b className="fs-20 m-fs-14">{t('footer.helps.help3')}</b>
                    <br />
                    <br />
                {t('policy.info11.content')}
            </div>
        </div>
    )
}

export default Content3