import { useEffect } from 'react';
import Footer from '../../../components/Footer';
import Header from "../../../components/Header";
import Banner from './Banner';
import Partner from './Partner';
import About from './About';
import Event from './Event';

const Edutainment = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='overflow-x-hidden'>
      <Header />

      <Banner />
      <Partner />
      <About />
      <Event />

      <Footer />
    </div>
  )
}

export default Edutainment;