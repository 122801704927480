import { Container, Row, Col } from 'react-bootstrap';

const Values = () => {
  return (
    <>
      <h5 className='text-center my-5 text-navy fw-700 fs-24 lh-30'>Our Values</h5>
      <Container className="mt-5 pb-5">
      <Row>
        <Col md={{ span: 4, offset: 1 }} className='bizValueCol'>Integrity</Col>
        <Col md={{ span: 4, offset: 2 }} className='bizValueCol'>Reliability</Col>
      </Row>
      <Row className='mt-5'>
        <Col md={{ span: 4, offset: 1 }} className='bizValueCol'>Quality Service</Col>
        <Col md={{ span: 4, offset: 2 }} className='bizValueCol'>Sustainability</Col>
      </Row>
      </Container>
    </>
  )
}

export default Values;