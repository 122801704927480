import { useEffect } from 'react';
import Footer from '../../../components/Footer';
import Header from "../../../components/Header";
import Banner from './Banner';
import Values from './Values';
import About from './About';

const Xpert = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='overflow-x-hidden'>
      <Header />

      <Banner />
      <Values />
      <About />

      <Footer />
    </div>
  )
}

export default Xpert;