import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import '../../assets/styles/styles.css'
import EurekaLogo from '../../assets/images/LogoEureka.png'
import { ReactComponent as WhatsappLogo } from '../../assets/images/Whatsapp.svg'
import Email from '../../assets/images/Email.svg'
import GooglePlay from '../../assets/images/GooglePlay.svg'
import Instagram from '../../assets/images/Instagram.svg'
import Twitter from '../../assets/images/Twitter.svg'
import LinkedIn from '../../assets/images/LinkedIn.svg'
import Facebook from '../../assets/images/Facebook.svg'
import { useTranslation } from 'react-i18next'
import { useState } from "react"
// import { BsGlobe2 } from "react-icons/bs";

const Footer = () => {
  const { t, i18n } = useTranslation()
  // const handleChangeLng = (lng) => {
  //   i18n.changeLanguage(lng)
  //   localStorage.setItem("lng", lng)
  // }

  const getInitialState = () => {
    const value = localStorage.getItem('lng') || 'en'
    return value
  }

  const [value, setValue] = useState(getInitialState)

  const handleChange = (e) => {
    setValue(e.target.value)
    i18n.changeLanguage(e.target.value)
    localStorage.setItem("lng", e.target.value)
  }

  return (
    <footer>
        <Row className="bg-milky">
          {/* Company */}
          <Col lg={4} xs={12} className="mt-3 mb-4">
            <div className="d-flex flex-column">
              <Link to="/">
                <img src={EurekaLogo} alt='Eureka' className='logo d-block mx-auto ms-md-5'></img>
              </Link> <br />
              <h6 className="fw-bold d-block mx-auto ms-md-5 dark-text">{t('footer.companyName')}</h6>
              <h6 className="fs-14 mt-3 d-block mx-auto ms-md-5 nowrap text-grey">{t('footer.companyAddress1')}</h6>
              <h6 className="fs-14 d-block mx-auto ms-md-5 text-grey">{t('footer.companyAddress2')}</h6>
              <br /> 
              <br />
              <h6 className="d-block mx-auto ms-md-5 fw-bold dark-text">{t('footer.downloadQuote')}</h6>
              <a className="text-decoration d-flex justify-content-center justify-content-md-start" href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" target="_blank" rel="noreferrer">
                <object className="logoGooglePlay pointer-events" type="image/svg+xml" aria-label="logo" data={ GooglePlay }></object>
              </a>
            </div>
          </Col>
          {/* Fitur */}
          <Col lg={3} xs={6} className="mt-4 ms-4 ms-md-0 ms-lg-0">
            <div className="d-flex flex-column">
              <h6 className="fw-bold dark-text">{t('footer.features.title')}</h6>
              <a className="text-decoration" href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" target="_blank" rel="noreferrer"><h6 className="fs-14 mt-2 text-grey">{t('footer.features.feature1')}</h6></a>
              <Link to='/product/book' className="text-decoration">
                <h6 className="fs-14 mt-2 text-grey">{t('footer.features.feature2')}</h6>
              </Link>
            </div>
          </Col>
          {/* Bantuan */}
          <Col lg={2} xs={5} className="mt-4">
            <div className="d-flex flex-column">
              <h6 className="fw-bold dark-text">{t('footer.helps.title')}</h6>
              <a className="text-decoration" href="https://medium.com/eureka-edutech" target="_blank" rel="noreferrer">
                <h6 className="fs-14 mt-2 text-grey">{t('footer.helps.help1')}</h6>
              </a>
              <Link to='/about' className="text-decoration">
                <h6 className="fs-14 mt-2 text-grey">{t('footer.helps.help2')}</h6>
              </Link>
              <Link to='/contact' className="text-decoration">
                <h6 className="fs-14 mt-2 text-grey">{t('footer.helps.help3')}</h6>
              </Link>
              <Link to='/terms' className="text-decoration">
                <h6 className="fs-14 mt-2 text-grey nowrap">{t('footer.helps.help4')}</h6>
              </Link>
              <Link to='/policy' className="text-decoration">
                <h6 className="fs-14 mt-2 text-grey">{t('footer.helps.help5')}</h6>
              </Link>
            </div>
          </Col>
          {/* Kontak */}
          <Col lg={3} xs={12} className="mt-4 ms-4 ms-md-0 ms-lg-0">
            <div className="d-flex flex-column">
              <h6 className="fw-bold dark-text">{t('footer.contacts.title')}</h6>
                {/* Whatsapp */}
                <div className="d-flex flex-row my-2">
                  <a className="text-decoration" href="https://api.whatsapp.com/send?phone=628111451220" target="_blank" rel="noreferrer">
                    <WhatsappLogo className="d-inline-block" />
                    <h6 className="fs-14 text-grey ms-2 d-inline-block">{t('footer.contacts.phone')}</h6>
                  </a>
                </div>
                {/* Email */}
                <div className="d-flex flex-row mt-2">
                  <a className="text-decoration" href="https://mail.google.com/mail/?view=cm&fs=1&to=info@eurekaedutech.com" target="_blank" rel="noreferrer">
                    <img src={Email} alt="Eureka" />
                    <h6 className="fs-14 text-grey ms-2 d-inline-block">{t('footer.contacts.email')}</h6>
                  </a>
                </div>
                {/*  */}
                <br />
                
                {/*  */}
                <h6 className="mt-3 fw-bold d-none d-sm-block dark-text">{t('footer.followUs')}</h6>
                <div className="d-block mx-auto mx-md-0">
                  <a href="https://www.instagram.com/eureka.edutech/" target="_blank" rel="noreferrer">
                    <object className="me-3 pointer-events" type="image/svg+xml" aria-label="logo" data={ Instagram }></object>
                  </a>
                  <a href="https://twitter.com/eureka_edutech" target="_blank" rel="noreferrer">
                    <object className="me-3 pointer-events" type="image/svg+xml" aria-label="logo" data={ Twitter }></object>
                  </a>
                  <a href="https://www.linkedin.com/in/edukasi-rekanan-anda/" target="_blank" rel="noreferrer">
                    <object className="me-3 pointer-events" type="image/svg+xml" aria-label="logo" data={ LinkedIn }></object>
                  </a>
                  <a href="https://www.facebook.com/eureka.edutech" target="_blank" rel="noreferrer">
                    <object className="me-3 pointer-events" type="image/svg+xml" aria-label="logo" data={ Facebook }></object>
                  </a>
                  <br />
                  <br />
                  <select value={value} onChange={handleChange} className="selectLng d-none">
                    <option value="en">English (US)</option>
                    <option value="id">Bahasa Indonesia</option>
                </select>
                </div>
                <br />

                {/* Change Language */}
                {/* <button onClick={ () => handleChangeLng("en")}>English</button>
                <button onClick={ () => handleChangeLng("id") }>Indonesia</button> */}
                {/* <BsGlobe2 /> */}

            </div>
          </Col>
        </Row>
        {/* Copyright */}
        <div className="text-center white-text bg-blue bg-footer fw-bold nowrap copyright">
          Copyright ©2022 PT. Edukasi Rekanan Anda. All rights reserved.
        </div>
    </footer>
  )
}

export default Footer