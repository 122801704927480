/* eslint-disable jsx-a11y/heading-has-content */
import IconSiap from '../../assets/images/IconSiap.svg'
import IconFokus from '../../assets/images/IconFokus.svg'
import IconMantap from '../../assets/images/IconMantap.svg'
import IconPaham from '../../assets/images/IconPaham.svg'
import { Trans, useTranslation } from 'react-i18next';

const Content1 = () => {
    
    const { t } = useTranslation()

    return (
        <div className="content1 vw-100">
            <Trans i18nKey='landing.subdesc'>
                <h6 className='m-fs-16 fs-30 color-blue fw-bold text-center'></h6>
            </Trans>
            {/*  */}
            <div id="row-content1" className='d-flex justify-content-center'>
                {/*  */}
                <div className="row">
                    {/* Card 1 */}
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="border-0 card mb-0 mb-md-3 mb-lg-3 ms-4 me-1" style={{"maxWidth": "600px"}}>
                            <div className="row g-0">
                                <div className="col-lg-4 col-md-4 col-sm-3 col-3">
                                    <object className="icons" type="image/svg+xml" aria-label="logo" data={ IconSiap }></object>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-9 col-9">
                                    <div className="card-body pb-0">
                                        <h5 className="card-title fw-bold fs-22 mt-0 mt-md-2 dark-text m-fs-14">{t('landing.benefits.section1.title')}</h5>
                                        <p className="card-text text-grey mt-0 mt-md-3 mt-lg-3 m-fs-12">{t('landing.benefits.section1.description')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Card 2 */}
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="border-0 card mb-0 mb-md-3 mb-lg-3 margin-icons" style={{"maxWidth": "600px"}}>
                            <div className="row g-0">
                                <div className="col-lg-4 col-md-4 col-sm-3 col-3">
                                    <object className="icons" type="image/svg+xml" aria-label="logo" data={ IconMantap }></object>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-9 col-9">
                                    <div className="card-body pb-0">
                                        <h5 className="card-title fw-bold fs-22 mt-0 mt-md-2 dark-text m-fs-14">{t('landing.benefits.section2.title')}</h5>
                                        <p className="card-text text-grey mt-0 mt-md-3 mt-lg-3 m-fs-12">{t('landing.benefits.section2.description')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
            <div className='d-flex justify-content-center'>
                {/*  */}
                <div className="row">
                    {/* Card 1 */}
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="border-0 card mb-0 mb-md-3 mb-lg-3 ms-4 me-1" style={{"maxWidth": "600px"}}>
                            <div className="row g-0">
                                <div className="col-lg-4 col-md-4 col-sm-3 col-3">
                                    <object className="icons" type="image/svg+xml" aria-label="logo" data={ IconFokus }></object>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-9 col-9">
                                    <div className="card-body pb-0">
                                        <h5 className="card-title fw-bold fs-22 mt-0 mt-md-2 dark-text m-fs-14">{t('landing.benefits.section3.title')}</h5>
                                        <p className="card-text text-grey mt-0 mt-md-3 mt-lg-3 m-fs-12">{t('landing.benefits.section3.description')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Card 2 */}
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="border-0 card mb-0 mb-md-3 mb-lg-3 margin-icons" style={{"maxWidth": "600px"}}>
                            <div className="row g-0">
                                <div className="col-lg-4 col-md-4 col-sm-3 col-3">
                                    <object className="icons" type="image/svg+xml" aria-label="logo" data={ IconPaham }></object>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-9 col-9">
                                    <div className="card-body pb-0">
                                        <h5 className="card-title fw-bold fs-22 mt-0 mt-md-2 dark-text m-fs-14">{t('landing.benefits.section4.title')}</h5>
                                        <p className="card-text text-grey mt-0 mt-md-3 mt-lg-3 m-fs-12">{t('landing.benefits.section4.description')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content1