import { Link } from "react-router-dom";
import { BsFillCaretRightFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Content1 = () => {
    const { t } = useTranslation()

    return(
        <div>
            <h5 className="gradient fw-bold white-text fs-30 m-fs-18 lh-160 text-center pt-lg-5 pt-md-5">{t('contact.title')}</h5>
            <div className="content1Contact mt-3">
                <Link to='/' className="text-decoration">
                    <h6 className="d-inline-block fw-500 fs-14 m-fs-12 lh-160 text-grey linkHome">{t('topmenu.home')}</h6>
                </Link>
                <BsFillCaretRightFill className="text-grey mx-2" size={ 12 } />
                <h6 className="d-inline-block fw-500 fs-14 m-fs-12 lh-160 blue-text">{t('contact.title')}</h6>
            </div>
        </div>
    )
}

export default Content1