import { Container, Row, Col } from 'react-bootstrap';

const XpertTopic = () => {
  return (
    <div className="bg-grey pt-5 pb-4">
      <h5 className="text-center fs-24 lh-30 fw-700 text-navy mb-4">Topic</h5>
      <Container className="px-3 mt-5 pb-5">
      <Row className='xpertTopicRow'>
        <Col className="xpertTopicCol">Business and Organization Transformation</Col>
        <Col className="xpertTopicCol">Corporate Negotiation</Col>
      </Row>
      <Row className='mt-5 xpertTopicRow'>
        <Col className="xpertTopicCol">Design Thinking</Col>
        <Col className="xpertTopicCol">Marketing Communication Workshop</Col>
      </Row>
      <Row className='mt-5 xpertTopicRow'>
        <Col className="xpertTopicCol">Power Communication</Col>
        <Col className="xpertTopicCol">Productivity Challange</Col>
      </Row>
      </Container>
    </div>
  )
}

export default XpertTopic