import { useEffect } from 'react';
import Footer from '../../../components/Footer';
import Header from "../../../components/Header";
import Banner from './Banner';
import Content1 from './Content-1';
import Content2 from './Content-2';
import Content3 from './Content-3';
import Content4 from './Content4';

const AndroidApp = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='overflow-x-hidden'>
      <Header />
      <Banner />
      <Content1 /> <br /> <br /> <br />
      <Content2 /> <br />
      <Content3 /> <br /> <br />
      <Content4 />

      <br></br>
      <br></br>

      <br></br>
      <br></br>


      <Footer />
    </div>
  )
}

export default AndroidApp;