import { Container, Row, Col, Image } from 'react-bootstrap';
import ImageBannerXpert1 from "../../../assets/images/ImageBannerXpert1.svg";
import ImageBannerXpert2 from "../../../assets/images/ImageBannerXpert2.svg";

const Banner = () => {
  return (
    <div className="bannerProductXpert">
      <Container>
        <Row>
          <Col xs={6}>
            <h5 className="dark-text fw-700 fs-52 lh-160 m-fs-24">Eureka Xpert</h5>
            <div className="text-grey fw-500 lh-160 fs-24">
              offers professional skills and talent development for corporates, startups, and universities
            </div>
          </Col>
          <Col xs={1}></Col>
          <Col xs={3} className="position-relative bannerXpertParent">
          <Image src={ImageBannerXpert1} className="img-fluid bannerXpertChild1" />
          <Image src={ImageBannerXpert2} className="img-fluid bannerXpertChild2" />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner;