import { Container, Row, Col, Card } from 'react-bootstrap';

const About = () => {
  return (
    <>
      <h5 className='text-center my-5 text-navy fw-700 fs-24 lh-30'>About Eureka Biz</h5>
      <div className="bizAbout">
        <div className="fw-500 fs-16 lh-160 text-grey">
          Eureka BIZ is a sub-product which helps corporates to develop their business so that it contributes to improving the country's economy, and widening employment opportunities especially in IT security, business development, sales and marketing, and others.
        </div>
        <h5 className="fw-700 fs-20 lh-25 dark-text mt-4">Our Objective</h5>
        <ul className="fw-500 fs-16 lh-160 dark-text">
          <li className="mt-4 mb-4">Become a business connector to connect companies to do business matching.</li>
          <li className="mb-4">Provide advices for client company consulting services.</li>
          <li>Helping companies conduct market research.</li>
        </ul>
        <br />
        <h5 className='mt-5 mb-4 text-navy fw-700 fs-24 lh-30'>Our Services</h5>
        <div className="fw-500 fs-16 lh-160 dark-text">
          Become a business connector to connect companies to do business matching.
        </div>
        <Container className='mb-5'>
          <Row className='text-center'>
            <Col xs={6} md={4} className=''>
              <div class="card mb-3 border-0" style={{"max-width": "18rem;"}}>
                <div class="card-body text-primary bizAboutBox">
                  <div class="border-0 bizAboutCardBody mt-2">Online Platform</div>
                  <p class="card-text bizAboutCardText">Eureka BIZ can be websites or applications that enable business owners and potential investors or business partners to create profiles, find and connect with fellow users, and seek suitable business opportunities.</p>
                </div>
              </div>
            </Col>
            <Col xs={6} md={4}>
              <div class="card mb-3 border-0" style={{"max-width": "18rem;"}}>
                <div class="card-body text-primary bizAboutBox">
                  <div class="border-0 bizAboutCardBody mt-2">Business Gathering</div>
                  <p class="card-text bizAboutCardText">Eureka BIZ provides business meetings arranged directly by the organizers. Here, business owners can meet and talk with potential investors, business partners or potential customers.</p>
                </div>
              </div>
            </Col>
            <Col xs={6} md={4}>
              <div class="card mb-3 border-0" style={{"max-width": "18rem;"}}>
                <div class="card-body text-primary bizAboutBox">
                  <div class="border-0 bizAboutCardBody mt-2">Partnership Program</div>
                  <p class="card-text bizAboutCardText">Eureka BIZ provides partnership programs organized by certain companies or organizations to help business owners find suitable business partners or potential investors.</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default About;