import GooglePlay from '../../../assets/images/GooglePlay.svg'
import { useTranslation } from 'react-i18next';

const Content1 = () => {
    const { t } = useTranslation()

    return (
        <div className="produkApp">
            <h6 className="fs-30 m-fs-16 fw-bold lh-160 color-blue text-center">{t('products.application.reasons.title')}</h6>
            <p className='text-grey m-fs-12'>{t('products.application.reasons.description')}</p>
            <a className="text-decoration" href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" target="_blank" rel="noreferrer">
                <object className="pointer-events mx-auto d-block" type="image/svg+xml" aria-label="logo" data={ GooglePlay }></object>
            </a>
        </div>
    )
}

export default Content1