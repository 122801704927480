module.exports = {
  topmenu: {
    home: `Beranda`,
    about: `Tentang`,
    products: {
      title: `Produk`,
      application: {
        title: `Aplikasi Eureka Edutech`,
        description: `Eureka Edutech membangun sebuah aplikasi pembelajaran yang....`,
      },
      book: {
        title: `Buku Panduan Asesmen Nasional`,
        description: `Eureka Edutech membangun sebuah aplikasi pembelajaran yang....`,
      },
    },
    blog: `Blog`,
    download: `Download Aplikasi`,
    viewMore: `Lihat Selengkapnya`,
  },
  landing: {
    appName: `Eureka Edutech`,
    description: `Persiapan asesmen nasional, belajar materi literasi dan numerasi bareng Eureka Edutech!`,
    subdesc: `<0>Persiapan Asesmen Nasional jadi mudah <br/> Bersama Eureka Edutech<0>`,
    benefits: {
      section1: {
        title: "Lebih Siap",
        description:
          "Siap menghadapi Asesmen Nasional dengan contoh soal di dalam materi, latihan soal hingga ujian.",
      },
      section2: {
        title: "Lebih Mantap",
        description:
          "Jadi bagian dari peningkatan nilai PISA di Indonesia dengan kesiapan untuk menghadapi Asesmen Nasional.",
      },
      section3: {
        title: "Lebih Fokus",
        description:
          "Fokus persiapan dengan memahami konsep literasi dan numerasi dalam Asesmen Nasional sesuai dengan indikator Pusmenjar.",
      },
      section4: {
        title: "Lebih Paham",
        description:
          "Tidak hanya latihan soal, ini waktunya memahami konsep pelajaran dengan Materi Literasi dan Numerasi yang sangat lengkap.",
      },
    },
    reasons: {
      title: `Kenapa Harus Eureka Edutech ?`,
      section1: {
        title: `Kurikulum Terkini`,
        description: `Materi dan bank soal yang disesuaikan dengan kurikulum terkini dari Pusmenjar`
      },
      section2: {
        title: `Materi Lengkap`,
        description: `Materi terlengkap, contoh soal, latihan soal, dan ujian yang dapat meningkatkan pemahaman untuk persiapan mengikuti Asesmen Nasional`
      },
      section3: {
        title: `Aplikasi Ringan`,
        description: `Aplikasi ringan dan sangat mudah digunakan`
      },
      section4: {
        title: `Pembahasan Detail`,
        description: `Khusus membahas Asesmen Nasional dengan materi dan bank soal yang lebih fokus dan paling detail`
      },
    },
    tutorials: {
      title: `Yuk Mulai Pakai Eureka Edutech !`,
      step1: `Download Aplikasi "Eureka Edutech" di Playstore`,
      step2: `Lakukan Login atau Registrasi bagi yang belum memiliki akun`,
      step3: `Pilih Materi Pembelajaran yang Kamu Inginkan`,
      step4: `Ada Materi, Latihan Soal dan Ujian pada tiap Topik Pembelajaran`,
      step5: `Terdapat kuis harian yang dapat kamu kerjakan setiap hari, juga lho !`,
    },
    faqs: {
      title: `Pertanyaanmu Ada Disini`,
      question1: {
        title: `Apa itu aplikasi Mobile EUREKA?`,
        description: `Sebuah aplikasi yang dapat membantu siswa untuk belajar materi seputar
        literasi, numerasi, dan sains dengan mudah. Kurikulum dalam aplikasi ini mengacu pada
        indikator utama yang diujikan dalam penilaian nasional 2022 untuk mendukung
        peningkatan peringkat PISA Indonesia.`
      },
      question2: {
        title: `Siapa saja yang bisa menggunakan aplikasi EUREKA?`,
        description: `Siapapun bisa menggunakan layanan EUREKA untuk mendukung kegiatan belajar dan persiapan asesmen nasional, 
        saat ini layanan baru tersedia untuk tingkatan SMA.`
      },
      question3: {
        title: `Bagaimana cara mendaftarkan diri pada layanan EUREKA?`,
        description: `Untuk saat ini pendaftaran layanan tersedia hanya pada aplikasi EUREKA versi android yang bisa diunduh melalui playstore.`
      },
      question4: {
        title: `Apakah selain siswa SMA boleh menggunakan aplikasi EUREKA?`,
        description: `Materi yang tersedia pada platform EUREKA dirancang untuk mempersiapkan siswa SMA menghadapi asesmen nasional yang tergolong baru di Indonesia. Platform untuk guru saat ini masih dalam tahap perancangan, namun untuk guru yang berkepentingan dalam kegiatan belajar mengajar boleh mengakses aplikasi EUREKA.`
      },
      question5: {
        title: `Apa saja fitur yang dimiliki oleh aplikasi EUREKA?`,
        description: `Aplikasi EUREKA memiliki beberapa fitur:
        Materi Pembelajaran
        Fitur ini berisi kumpulan modul-modul pembelajaran sebagai panduan
        Latihan Soal
        Fitur ini berisi kumpulan soal sebagai bahan praktik untuk melatih kemampuan siswa sebelum melakukan ujian simulasi
        Ujian
        Fitur ini berisi soal-soal untuk mengukur pemahaman dan kemampuan siswa atas materi numerasi dan literasi yang telah dipelajari
        `
      },
      question6: {
        title: `Bagaimana cara menghubungi layanan pelanggan EUREKA?`,
        description: `Untuk layanan pelanggan, kamu bisa menghubungi beberapa dari kami pada platform berikut:
        Email		: info@eurekaedutech.com
        Twitter		: @eureka_edutech
        Instagram	: @eureka.edutech`
      },
      question7: {
        title: `Bagaimana cara untuk melakukan pengaduan konsumen?`,
        description: `Apabila kamu mengalami kendala, kamu dapat langsung menghubungi kami pada kontak berikut:
        Email : info@eurekaedutech.com`
      },
      question8: {
        title: `Apakah siswa SMK dan mahasiswa bisa menggunakan aplikasi EUREKA?`,
        description: `Bisa, karena ini adalah aplikasi belajar dan siapa pun bisa menggunakannya, 
        soal untuk asesmen nasional juga diperlukan bagi semua pelajar.`
      },
    }
  },
  about: {
    title: `About`,
    descriptions: {
      description1: ` adalah perusahaan teknologi pendidikan yang berfokus kepada asesmen nasional untuk siswa di Indonesia yang didirikan berdasarkan hukum yang berlaku di Indonesia pada tahun 2020 di Jakarta Selatan. Eureka Edutech didirikan oleh sekumpulan penggiat pendidikan lulusan Ph.D luar negeri yang terdiri dari berbagai latar belakang profesi seperti pembina tim olimpiade nasional maupun internasional, pengusaha, penggiat literasi dan konsultan pendidikan.`,
      description2: `Eureka Edutech ingin menjawab tantangan pendidikan nasional secara holistik, terutama yang mengacu pada hasil pemeringkatan PISA (OECD) yang menjadi acuan seberapa baik pemahaman siswa-siswi di suatu negara dalam kemampuan memahami bacaan/ membaca, ilmu matematika serta sains.`,
      description3: `Dengan terbitnya PERATURAN MENTERI PENDIDIKAN, KEBUDAYAAN, RISET, DAN TEKNOLOGI REPUBLIK INDONESIA, NOMOR 17 TAHUN 2021, TENTANG ASESMEN NASIONAL, maka babak baru dunia pendidikan dasar dan menengah di Indonesia dimulai. Dengan spirit Merdeka Belajar, maka UN atau Ujian Nasional resmi diganti dengan pendekatan Asesmen Nasional.`,
      description4: `Eureka Edutech ingin menggaris bawahi menyangkut PerMen tersebut terkait tujuan dari Asesmen Nasional:`,
      description5: `Mendorong guru mengembangkan kompetensi kognitif yang mendasar sekaligus karakter murid secara utuh.`,
      description6: `Menunjukkan apa yang seharusnya menjadi tujuan utama sekolah, yakni pengembangan kompetensi dan karakter murid.`,
      description7: `Memberi gambaran tentang karakteristik esensial sekolah yang efektif untuk mencapai tujuan tersebut. Ukuran kompetensi kognitif seperti tersebut di atas mencakup literasi membaca dan numerasi.`,
      description8: `Eureka Edutech mengembangkan aplikasi pembelajaran untuk mempersiapkan siswa menjadi pembelajar yang lebih baik dalam bidang literasi serta numerasi, yang juga bermanfaat bagi mereka yang masuk atau terpilih untuk mengikuti proses Asesmen Kompetensi Minimum melalui sekolah mereka masing-masing. Apa yang dapat kami persembahkan bagi generasi muda kita yang masih duduk dan berjuang di bangku pendidikan selain bank soal yang relevan, yang mendorong kemampuan kognitif mereka, yang pada akhirnya akan membentuk kekuatan keunggulan generasi muda kita menyongsong masa depan dengan daya saing yang lebih tinggi.`,
    },
    teams: {
      title: `Tim Kami`,
      member1: {
        name: `Herman Huang, M.Sc., MBA`,
        position: `Founder`,
        description: `Meraih gelar Master of Business di IMD Business School, Swiss. Berpengalaman sebagai technopreneur selama 16 tahun di beberapa sektor bisnis dan berkontribusi dalam mengembangkan kemampuan wirausaha muda.`,
      },
      member2: {
        name: `AC Mahendra K Datu, Ph.D`,
        position: `Co-Founder`,
        description: `Meraih gelar Ph.D di The National University of Singapore (NUS), SEAS Trade and Strategic Industry. Berpengalaman sebagai mentor di kampus dan strategi perusahaan. Desainer OSCI dan kolumnis inovasi di media besar. Beliau juga aktif sebagai penulis buku sejak 2009 tentang bisnis dan manajemen, ekonomi, keuangan, dan produktivitas sumber daya manusia.`,
      },
      member3: {
        name: `Herry Kwee, Ph.D`,
        position: `Director & Numeration Content Team`,
        description: `Meraih gelar Ph.D di bidang Fisika dari College of William and Mary. Berpengalaman lebih dari 10 tahun di bidang pendidikan. Aktif sebagai pembina tim olimpiade fisika Indonesia sejak tahun 2000 serta tim akademik dan juri pelatihan/kompetisi olimpiade tingkat internasional dan nasional.`,
      },
      member4: {
        name: `Hendra Kwee, Ph.D`,
        position: `Director & Numeration Content Team`,
        description: `Meraih gelar Ph.D di bidang Fisika dari College of William and Mary. Berpengalaman 19 tahun di bidang pendidikan. Aktif sebagai pemimpin tim olimpiade fisika Indonesia dan olimpiade lainnya dan telah meraih medali di setiap kompetisi yang diikuti oleh para peserta pelatihan.`,
      },
      member5: {
        name: `Jong Anly Tan, Ph.D`,
        position: `Numeration Content Team`,
        description: `Meraih gelar Ph.D di bidang Fisika dari College of William and Mary. Berpengalaman 11 tahun sebagai leader, juri dan trainer di beberapa olimpiade fisika nasional dan internasional. Saat ini aktif sebagai pembina tim Olimpiade Fisika Indonesia sejak 2009.`,
      },
      member6: {
        name: `Zainul Abidin, Ph.D`,
        position: `Numeration Content Team`,
        description: `Memperoleh gelar Ph.D dalam Fisika Teoretis dari College of William and Mary. Berpengalaman 9 tahun sebagai pelatih dan ketua tim Delegasi Indonesia di Olimpiade Fisika Asia. Prestasi yang diraih antara lain Medali Perak pada Olimpiade Fisika Asia ke-1 di Karawaci, Indonesia 2000 dan Honorable Mention pada Olimpiade Fisika Internasional ke-31 di Leicester, Inggris 2000.`,
      },
      member7: {
        name: `ign. Yophiandi Kurniawan M.Sos`,
        position: `Literacy Content Team`,
        description: `20 tahun pengalaman dalam jurnalisme dan literasi. Pernah bekerja di beberapa portal berita terkemuka di Indonesia.`,
      },
      member8: {
        name: `Elvita Sari`,
        position: `Project Leader`,
        description: `Meraih gelar sarjana sains (S.Si) pada tahun 2018 dan telah berkecimpung selama 6 tahun di dunia pengembangan pendidikan. Selain itu juga berpengalaman di bidang marketing, penelitian, dan manajemen proyek di komunitas dan startup, khususnya pendidikan dan human development.`,
      },
      member9: {
        name: `Iftihal Muslim Rahman`,
        position: `Marketing Executive`,
        description: `Pengalaman 6 tahun di bidang jurnalistik. Seorang public speaker, penulis puisi dan fiksi, dan beauty content creator.`,
      },
      member10: {
        name: `Cahyarani Paramesti`,
        position: `Marketing Executive`,
        description: `Pengalaman 3 tahun sebagai pianis, pelukis, dan penari, kepala divisi artistik dan publikasi, desain grafis dan penulis konten.`,
      },
      member11: {
        name: `Faturachman Asyari Oktavian`,
        position: `UI/UX Designer`,
        description: `Meraih gelar Sarjana Teknik pada bidang Teknik Komputer Universitas Diponegoro, Semarang. Pengalaman 3 tahun dalam bidang UI dan UX Design.`,
      },
      member12: {
        name: `Putri Husnul Aprilia`,
        position: `Sales`,
        description: `Meraih gelar sarjana sosial (S.sos) pada tahun 2019 dan berpengalaman sebagai asisten konsultan managemen dan Business Development selama dua tahun.`,
      },
      member13: {
        name: `Hadistian S.Pd`,
        position: `Literacy Content Team`,
        description: `Meraih gelar sarjana pendidikannya pada bidang Pendidikan Geografi di Universitas Negeri Jakarta. Pernah menjadi kontributor pada rubrik mahasiswa serta opini di Koran Sindo dan Media Indonesia.`,
      },
      member14: {
        name: `Muhammad Khairul Ihsan S.Si`,
        position: `Numeration Content Team`,
        description: `Meraih gelar sarjana sains pada bidang Matematika di Universitas Hasanuddin. Pengalaman 10 tahun dalam pengajaran bidang matematika dan pelatih Olimpiade nasional.`,
      },
      member15: {
        name: `Dimas Widianto Ramadhan S.Si`,
        position: `Numeration Content Team`,
        description: `Meraih gelar sarjana sains pada bidang Fisika Instrumentasi di Universitas Islam Negeri Syarif Hidayatullah Jakarta. Pernah menjadi asisten laboratorium fisika di Pusat Laboratorium Terpadu UIN Jakarta.`,
      },
      member16: {
        name: `Fadella Amirah Siregar`,
        position: `Full Stack Developer`,
        description: `Berpengalaman sebagai full stack web developer dengan keterampilan: Web Development, Backend Development, Web Socket, Cloud Computing, Git / Github, Databases.`,
      },
      member17: {
        name: `Daniel Saputra`,
        position: `Full Stack Developer`,
        description: `Berpengalaman sebagai full stack developer dengan keterampilan: Backend development, Web Applications, Data scraping, Cloud Computing, and IT Automation.`,
      },
      member18: {
        name: `Ikrima Amanda Wulandari`,
        position: `Android Developer`,
        description: `Berpengalaman 2 tahun sebagai pengembang aplikasi android dengan skills:Kotlin, API, XML, Android studio, Git / Github, MVVM, MVP, Firebase Realtime DB, NodeJS, ExpressJS, MySQL.`,
      },
    }
  },
  products: {
    application: {
      title: `Eureka Edutech App`,
      description1: `Belajar dimana Saja dan Kapan Saja bareng`,
      description2: `Eureka Edutech.`,
      downloadQuote: `Coba Aplikasinya, GRATIS`,
      reasons: {
        title: `Kenapa pilih Eureka Edutech App ?`,
        description: `Eureka Edutech membangun sebuah aplikasi pembelajaran yang dapat membantu siswa belajar dan melakukan simulasi asesmen nasional secara real-time dengan bank soal yang sudah diramu sedemikian rupa. Salah satu fitur unggulan dari aplikasi ini adalah siswa dapat mempelajari materi literasi dan numerasi secara langsung yang dilengkapi dengan contoh soal dan pembahasan, serta peserta dapat melakukan simulasi ujian sesuai topik yang dipilih. Selain itu, ada fitur daily question untuk mengasah daya ingat siswa setiap harinya dan fitur activities untuk merefresh kembali aktivitas yang telah dilakukan sebelumnya. Udah gak sabar berpetualang diaplikasi kita? Yuk download aplikasinya sekarang, GRATIS!`,
      },
      features: {
        title: `Fitur Belajar Apa yang Bisa Kamu Gunakan ?`,
        feature1: `Ribuan Soal Literasi dan Numerasi`,
        feature2: `Uji Pemahaman Kamu Biar Makin Percaya Diri`,
        feature3: `Latihan Soal yang Nggak Bikin Ribet`,
        feature4: `Progres Kamu Akan Selalu Disimpan`,
        feature5: `Beragam Artikel Menarik Buat Menambah Wawasan`,
        feature6: `Simpan Materi Favorit Kamu`,
      },
      subscriptions: {
        title: `Langganan Eureka Edutech dan Nikmati Benefitnya`,
        benefitTitle: `Yang Didapatkan`,
        choose: `Pilih Paket`,
        downloadQuote: `Yuk download aplikasinya sekarang, GRATIS!`,
        recommended: 'Rekomendasi',
        plan1: {
          title: `Starter Pack`,
          price: `Rp 120.000`,
          discountPrice: `Rp 95.000`,
          duration: `1 bulan`,
          benefit1: `Akses semua materi tanpa batas`,
          benefit2: `Latihan soal dan pembahasan gratis`,
          benefit3: `Asah skillmu di kuis harian sebebasnya`,
          benefit4: `Akses simulasi ujian 1 bulan`,
          benefit5: `Akses webinar Kumpas Total Asesmen Nasional selama 1 bulan`,
          benefit6: `Merchandise menarik`
        },
        plan2: {
          title: `Super Pack`,
          price: `Rp 1.200.000`,
          discountPrice: `Rp 800.000`,
          duration: `12 bulan`,
          benefit1: `Akses semua materi tanpa batas`,
          benefit2: `Latihan soal dan pembahasan gratis`,
          benefit3: `Asah skillmu di kuis harian sebebasnya`,
          benefit4: `Akses simulasi ujian 12 bulan`,
          benefit5: `Akses webinar Kumpas Total Asesmen Nasional selama 12 bulan`,
          benefit6: `Gratis 15% buku panduan Asesmen Nasional`,
          benefit7: `Gratis konsultasi 24 pertanyaan bersama tutor`,
          benefit8: `Merchandise menarik`,
        },
        plan3: {
          title: `Basic Pack`,
          price: `Rp 443.000`,
          discountPrice: `Rp 399.000`,
          duration: `6 bulan`,
          benefit1: `Akses semua materi tanpa batas`,
          benefit2: `Latihan soal dan pembahasan gratis`,
          benefit3: `Asah skillmu di kuis harian sebebasnya`,
          benefit4: `Akses simulasi ujian 6 bulan`,
          benefit5: `Akses webinar Kumpas Total Asesmen Nasional selama 6 bulan`,
          benefit6: `Merchandise Menarik`,
          benefit7: `Gratis 10% buku panduan Asesmen Nasional`,
        }
      }
    },
    book: {
      title: `Buku Panduan Asesmen Nasional 2022`,
      description: `Persiapan asesmen nasional, belajar materi literasi dan numerasi bareng Eureka Edutech!`,
      orderButton: `Pesan Sekarang !`,
      longDescription: {
        title: `Penunjang Materi Pembelajaranmu`,
        description: `Sebagai penunjang dan membantu keterbatasan dalam belajar online, kami juga menyediakan buku paket pembelajaran mengenai materi-materi yang diujikan dalam asesmen nasional yang sudah disesuaikan dengan indikator utama yang tertera pada panduan pusmenjar. Saat ini, buku ini ditujukan kepada siswa-siswi SMA di Indonesia untuk membantu persiapan Asesmen Nasional di tahun 2022. Buku ini dilengkapi dengan panduan materi pembelajaran beserta contoh soal dan pembahasan, serta dua paket simulasi ujian beserta kunci jawaban. Jadi, mau belajar bareng? Yuk Pre-Order sekarang!`,
      },
      benefits: {
        title: `Apa Saja yang Akan Kamu Dapatkan ?`,
        benefit1: `Materi Asesmen Nasional`,
        benefit2: `Latihan Soal`,
        benefit3: `Pembahasan Lengkap`,
        benefit4: `Simulasi Ujian`,
      },
      question: {
        title: `Ingin belajar tentang materi pembelajaran Asesmen Nasional 2022 ?`,
        solution: `Buku Panduan Asesmen Nasional 2022`,
        orderQuote: `Pesan dan belajar bersama Eureka Edutech !`,
      }
    }
  },
  contact: {
    title: 'Kontak Kami',
    subtitle: 'Hubungi Kami',
    desc: 'Isi form berikut jika Anda memiliki pertanyaan, keluhan, pengajuan kerja sama / sponsorship. Kami akan menghubungi Anda kembali secepatnya.',
    form: {
      notes: 'Semua bagian wajib diisi.',
      name: 'Nama',
      email: 'Alamat Email',
      phone: 'Nomor Handphone',
      subject: 'Subjek',
      message: 'Isi Pesan',
      sendBtn: 'Kirim Pesan'
    }
  },
  terms: {
    title: 'Syarat & Ketentuan',
    subtitle: 'Syarat dan Ketentuan Penggunaan Aplikasi Eureka Edutech',
    desc: {
      paragraph1: '<0 className="m-fs-12">EUREKA</0> adalah produk yang dikembangkan, dimiliki serta dikelola oleh PT. Edukasi Rekanan Anda. <0 className="m-fs-12">EUREKA</0> merupakan sebuah layanan edukasi berupa informasi dalam bentuk aplikasi perangkat lunak ("Platform").',
      paragraph2: 'Dengan melakukan unduhan, memasang, dan/atau menggunakan Platform ini, dengan kata lain Anda telah menyetujui bahwa Anda telah membaca, memahami, dan menyetujui seluruh informasi, syarat-syarat, dan ketentuan-ketentuan penggunaan. Adapun dibuatnya Syarat  dan Ketentuan, dengan tujuan untuk menandakan bahwa adanya perjanjian hukum antara Pengguna ("Anda") serta dengan <0 className="m-fs-12">EUREKA</0>. Namun, harap diperhatikan bahwa syarat ketentuan dan Kebijakan Privasi akan terjadi perubahan dari waktu ke waktu serta hasil dari perubahan yang akan terjadi akan kami beritahukan kepada Anda pada saat anda melakukan akses pada Platform.',
      paragraph3: 'Apabila Anda ingin menghapus akun di platform ini, Anda dapat melakukan pengajuan permohonan penghapusan akun. Mohon pahami secara keseluruhan syarat dan ketentuan penggunaan sebelum mengakses platform ini. Dengan menekan tanda centang artinya anda menyetujui syarat dan ketentuan penggunaan aplikasi ini.'
    },
    requirements: {
      title: 'Ketentuan Umum',
      list1: {
        item1: 'Semua kata pengganti di bawah Syarat dan Ketentuan ini untuk "Anda" merujuk pada Pengguna Aplikasi ("User"), untuk "EUREKA" dan "kami" merujuk pada PT. Edukasi Rekanan Anda.',
        item2: 'Koneksi internet menjadi kebutuhan utama yang diperlukan untuk dapat melakukan akses pada semua layanan yang ada. Segala bentuk biaya yang terjadi akibat dari penggunaan aplikasi merupakan tanggung jawab pengguna sepenuhnya.',
        item3: {
          content: 'Beberapa fitur yang ada pada Aplikasi ini adalah:',
          list1: 'Artikel bacaan',
          list2: 'Materi dan bank soal',
          list3: 'Aktivitas pengguna',
          list4: '<0 className="my-1 m-fs-12">Layanan lain apapun yang dapat kami tambahkan dari waktu ke waktu</0>'
        },
        item4: 'Platform hanya merupakan sebuah sarana untuk memudahkan dalam menggunakan aplikasi pendidikan yang sudah dikembangkan.',
        item5: 'Kami adalah perusahaan teknologi pendidikan. Kami tidak mempekerjakan Penyedia Layanan, dan kami tidak bertanggung jawab atas tindakan, kecerobohan, kelalaian, dan/atau kelengahan Penyedia Layanan. Platform merupakan layanan informasi dan komunikasi online yang disediakan oleh EUREKA.'
      }
    },
    termsOfUse: {
      title: 'Ketentuan Penggunaan Aplikasi',
      list: {
        item1: 'Dengan mengakses platform/ menggunakan layanan, Anda menyatakan dan menjamin terkait data pribadi Anda yang dipublikasikan atau tampilkan di platform bersifat data akurat. Apabila data yang diberikan tidak akurat, Kami berhak untuk membatalkan akun Anda atau menghapus akun Anda di database Kami.',
        item2: 'Dengan Anda mengunduh platform, Anda setuju untuk mematuhi semua panduan, pemberitahuan dan kebijakan terkait penggunaan layanan akses ke platform dan Anda menyetujui untuk melakukan aktivitas apapun terkait layanan dengan cara yang baik.',
        item3: 'Akses ke platform dilindungi oleh kata sandi. Maka Anda menyatakan dan menjamin untuk tidak memberikan kata sandi akun Anda ke siapapun yang dapat mengakses platform dengan data diri Anda. Apabila Anda memberikan wewenang ke orang lain untuk mengakses platform, Kami tidak bertanggung jawab atas penggunaan yang melanggar aturan karena aplikasi mendata akun tersebut milik Anda. Kecuali Anda memberikan informasi terkait akun Anda.',
        item4: 'Anda menyatakan bahwa akan menggunakan platform ini hanya untuk memperoleh layanan. Anda tidak diperkenankan untuk menyalahgunakan platform atau mengubah dengan cara apapun. Kami berhak untuk menutup akun Anda atau tidak diperkenankan mendaftarkan kembali dengan data diri Anda apabila Anda melakukan aktivitas yang menyalahi aturan Kami.'
      }
    },
    features: {
      title: 'Layanan dan Fitur',
      list1: {
        title: 'Artikel Bacaan',
        item1: 'Fitur ini memfasilitasi pengguna dengan artikel seputar literasi dan numerasi',
        item2: 'Anda dapat mengetahui artikel terbaru dari EUREKA dan program menarik dari Eureka'
      },
      list2: {
        title: 'Materi dan Bank Soal',
        item1: 'Fitur ini berisi kumpulan materi pelajaran serta tips dan trik pembahasan dalam memecahkan soal',
        item2: 'Fitur ini juga berisi kumpulan bank soal sebagai bahan latihan dengan jenis soal yang bervariasi'
      },
      list3: {
        title: 'Aktivitas Pengguna',
        item1: 'Fitur ini merekam jejak aktivitas pengguna saat menggunakan Platform EUREKA'
      }
    },
    liability: {
      title: 'Pembatasan Tanggung Jawab',
      item1: 'Aplikasi tidak bertanggung jawab atas kondisi posisi pengguna.',
      item2: 'Aplikasi tidak bertanggung jawab atas tindakan yang diambil pengguna setelah menggunakan layanan fitur aplikasi.',
      item3: 'Aplikasi tidak bertanggung jawab atas biaya yang dikeluarkan oleh pengguna setelah menggunakan layanan fitur aplikasi.',
      item4: 'Aplikasi tidak bertanggung jawab atas ketidaksesuaian materi yang diberikan pada Platform dengan ekspektasi pengguna.'
    },
    notification: {
      title: 'Pemberitahuan',
      content: 'Semua pemberitahuan dan/atau permintaan informasi kepada dan/atau yang berkaitan dengan kami akan diproses secara tertulis dan untuk dapat dikirimkan ke alamat kami di <0 className="m-fs-12">Graha Satria 1 Suite 203, Jalan RS Fatmawati No. 5 RT.01/RW.09, Cilandak Barat, Jakarta Selatan, Jakarta 12430 </0> atau alamat email kami di : <0 className="m-fs-12">eureka.learningplatform@gmail.com</0> atau call center di <0 className="m-fs-12">(021) 3451737</0>.'
    },
    security: {
      title: 'Keamanan Aplikasi',
      content: 'Kami berusaha untuk menjaga sistem aplikasi kami agar dapat berjalan dan berfungsi dengan baik. Anda memahami bahwa teknologi saat ini sudah sangat mumpuni untuk menyusup ke dalam sistem kami. Karenanya setiap pesan atau informasi pribadi mungkin dapat dibajak dengan mudah. Oleh karena itu, kami selaku pembuat serta pemilik selalu melakukan pembaruan keamanan untuk sistem guna bertujuan untuk mencegah adanya tindak kriminal yang dilakukan oleh pihak-pihak yang tidak bertanggung jawab yang dapat merugikan kami dan juga anda.'
    },
    onesignal: {
      title: 'Onesignal',
      content: 'Kami menggunakan data email dan/atau nomor telepon user untuk mengirimkan notifikasi kepada pengguna.'
    },
    closing: {
      title: 'Penutup',
      list: {
        item1: 'Hubungan yang terjadi antara pengguna dengan kami bukan merupakan sebuah hubungan dalam bentuk keagenan, kemitraan, usaha patungan, karyawan-perusahaan atau pemilik waralaba-pewaralaba melainkan suatu hubungan independen.',
        item2: 'Judul yang ada pada Ketentuan Penggunaan yang dibuat hanya bertujuan sebagai acuan sebagai syarat dan ketentuan pada aplikasi ini.',
        item3: 'Kami memiliki hak untuk menginvestigasi dan menuntut hak Kami untuk setiap pelanggaran atas Ketentuan Penggunaan ini sepanjang yang dimungkinkan dan diperkenankan oleh hukum. Anda dengan ini mengakui bahwa Kami memiliki hak untuk memonitor akses penggunaan situs dan Aplikasi untuk memastikan kepatuhan dengan Ketentuan Penggunaan ini, atau untuk mematuhi peraturan yang berlaku atau perintah atau persyaratan dari pengadilan, lembaga administratif atau badan pemerintahan lainnya.',
        item4: 'Pengguna aplikasi sangat tidak diperbolehkan untuk melakukan pencucian uang dan/atau menggunakan uang yang memiliki sumber yang menurut hukum tidak sah. Kami berhak untuk melaporkan segala bentuk transaksi yang dirasa patut dicurigai sebagai tindak pidana pencucian uang kepada pihak yang berwajib.',
        item5: 'Jika dikemudian hari ditemukan tindakan manipulasi/kecurangan, seperti menutup atau membatalkan akun, menarik subsidi, menarik cashback, membatalkan transaksi, menahan dana, serta hal-hal lainnya jika ditemukan indikasi kecurangan, pencucian uang atau pelanggaran yang dilakukan oleh pengguna. Kami berhak dan tanpa memberikan pemberitahuan sebelumnya untuk melakukan tindakan-tindakan yang diperlukan sesuai dengan ketentuan hukum yang berlaku pada wilayah negara Republik Indonesia.'
      }
    }
  },
  policy: {
    title: 'Kebijakan Privasi',
    paragraph1: 'berkomitmen untuk menghormati dan melindungi privasi Anda. Kami memahami betapa pentingnya data pribadi Anda dan kami tidak akan membagikannya kepada orang-orang atau pihak ketiga lainnya tanpa izin Anda. Silahkan baca Kebijakan Privasi berikut untuk mempelajari lebih lanjut tentang bagaimana kami akan menjunjung tinggi janji kami kepada Anda.',
    paragraph2: 'Kebijakan ini menjelaskan bagaimana kami mengumpulkan, menggunakan, dan mengungkapkan data pribadi Anda yang kami peroleh saat Anda menggunakan Eureka Edutech. Dengan terus menggunakan atau mengakses aplikasi kami, itu berarti Anda telah menerima praktik-praktik yang diuraikan dalam Kebijakan ini.',
    info1: {
      title: 'Informasi yang Kami Akses dan Kumpulkan',
      paragraph1: '<0 className="m-fs-12">Informasi Akun:</0> Dengan mendaftarkan diri pada aplikasi di Eureka Edutech, Anda sudah memberikan nomor ponsel. Kemudian, Anda telah memberi kami otorisasi untuk mengakses panggilan telepon Anda agar Anda dapat memverifikasi akun dan melakukan perubahan kata sandi apabila Anda lupa terhadap kata sandi Anda melalui panggilan singkat untuk mendapatkan sebuah OTP (One Time Password). Informasi lainnya seperti nama, email, nomor telepon, provinsi, kota, dan alamat sekolah juga dapat dilihat dan disimpan oleh kami.',
      paragraph2: '<0 className="m-fs-12">Data dari Pengaturan Perangkat Seluler:</0> Kami memperoleh informasi yang Anda izinkan kami terima seperti akses panggilan telepon dan lokasi Anda untuk menyediakan fitur layanan kami.'
    },
    info2: {
      title: 'Informasi yang Secara Otomatis Terkumpul',
      paragraph1: '<0 className="m-fs-12">Informasi Penggunaan dan Log:</0> Kami mengumpulkan informasi terkait layanan, diagnostik, dan kinerja. Ini termasuk informasi tentang aktivitas Anda (seperti bagaimana Anda menggunakan layanan aplikasi kami, bagaimana Anda menggunakan layanan website kami, dan lain-lain), berkas log, laporan diagnostik, data crash, website, serta laporan kinerja.',
      paragraph2: '<0 className="m-fs-12">Informasi Perangkat Seluler dan Koneksi:</0> Kami mengumpulkan informasi khusus mengenai perangkat Anda ketika menginstal, mengakses, atau menggunakan layanan kami. Ini termasuk informasi seperti model perangkat keras, sistem operasi, informasi browser, alamat IP, informasi jaringan seluler termasuk nomor ponsel, dan pengidentifikasi perangkat.',
      paragraph3: '<0 className="m-fs-12">Cookie:</0> Kami menggunakan cookie untuk beroperasi, termasuk untuk menyediakan layanan kami yang berbasis web. Kami juga menggunakannya untuk meningkatkan pengalaman Anda dengan memahami bagaimana layanan kami digunakan, dan menyesuaikan layanan kami agar sesuai dengan Anda. Kami dapat menggunakan cookie dan teknologi serupa di situs web kami untuk mengumpulkan informasi seperti tanggal dan waktu kunjungan, lokasi geografis, halaman yang dilihat, alamat IP, tautan ke atau dari halaman manapun, jenis dan versi browser, sistem operasi, sumber rujukan, dan waktu yang dihabiskan di situs web kami.',
      paragraph4: '<0 className="m-fs-12">Informasi Status:</0> Kami mengumpulkan informasi tentang perubahan bagian profil Anda, seperti apakah Anda telah mengubah informasi profil Anda pada fitur edit profile.'
    },
    info3: {
      title: 'Informasi Pihak Ketiga',
      paragraph1: '<0 className="m-fs-12">Penyedia Pihak Ketiga:</0> Kami bekerja dengan penyedia pihak ketiga untuk menyediakan layanan atas nama kami, untuk meningkatkan layanan atau untuk membantu kami dalam menganalisis bagaimana layanan kami digunakan. Kami dapat menerima informasi dari penyedia pihak ketiga tergantung pada kebijakan dan pengaturan akun Anda dengan pihak ketiga yang berlaku. Kami tidak memiliki kendali dan tidak bertanggung jawab atas penggunaan atau pengungkapan informasi pribadi Anda oleh pihak ketiga.'
    },
    info4: {
      title: 'Data Pengguna',
      paragraph1: 'Kami menyimpan informasi di server yang berlokasi di Indonesia. Kami bekerja dengan pihak ketiga untuk menyediakan berbagai layanan terkait teknologi. Data pengerjaan soal Anda tidak akan dibagikan dengan perusahaan atau layanan lain manapun. Data Anda secara aman disimpan dan tidak digunakan untuk kepentingan apapun selain pengembangan aplikasi dan layanan kami.'
    },
    info5: {
      title: 'Kerahasiaan Informasi dan Layanan Konsumen',
      item1: 'Eureka Edutech tidak akan memberikan atau mendistribusikan informasi pribadi para pengguna yang didapat melalui penggunaan aplikasi kepada pihak ketiga. Akan tetapi, hal ini tidak termasuk ketika informasi tersebut diminta oleh pihak berwenang terkait, antara lain: Kepolisian Negara Republik Indonesia, Pejabat Pegawai Negeri Sipil tertentu dan Kejaksaan Standards of Commision mengajukan permohonan informasi, atau dalam hal lain melalui prosedur hukum.',
      item2: 'Eureka Edutech harus menanggapi secara cepat seluruh keluhan para pengguna terhadap layanan. Bila penjelasan singkat mengenai keluhan tersebut tidak mencukupi, Eureka Edutech akan memposting atau menampilkan penjelasan di layar aplikasi atau melalui email dan akan merencanakan peningkatan sistem.',
      item3: 'Eureka Edutech bersandar pada Perundang-undangan terkait Intellectual Property Right (HAKI) dan data privasi pengguna.'
    },
    info6: {
      title: 'Bagaimana Kami Menggunakan Informasi',
      list: {
        content: 'Kami menggunakan semua informasi yang dapat membantu kami dalam memperbaiki hal-hal sebagai berikut:',
        item1: '<0 className="m-fs-12">Layanan:</0> Kami mengoperasikan dan menyediakan layanan kami, termasuk customer service, dan juga meningkatkan, memperbaiki, dan menyesuaikan layanan kami. Dari kegiatan tersebut, kami menganalisis informasi yang ada untuk mengevaluasi dan meningkatkan layanan kami, meneliti, mengembangkan, dan menguji layanan serta fitur baru. Kami juga menggunakan informasi Anda untuk merespons Anda ketika Anda menghubungi kami.',
        item2: '<0 className="m-fs-12">Keselamatan dan Keamanan:</0> Kami memverifikasi akun dan aktivitas, dan mempromosikan keselamatan dan keamanan di dalam dan di luar layanan kami, seperti dengan menyelidiki aktivitas yang mencurigakan atau pelanggaran terhadap persyaratan kami, dan untuk memastikan bahwa layanan kami digunakan secara legal.'
      }
    },
    info7: {
      title: 'Informasi yang Dibagikan',
      content: 'Anda membagikan informasi Anda saat Anda menggunakan Eureka Edutech untuk mengganti kata sandi dan mengerjakan soal, serta kami memberikan informasi Anda untuk membantu kami mengoperasikan, menyediakan, meningkatkan, memahami, menyesuaikan, mendukung, dan memasarkan layanan kami.',
      list: {
        item1: '<0 className="m-fs-12">Informasi Akun:</0> Nama, nomor ponsel, email, provinsi, kota, nama sekolah, kelas, dan jurusan dapat tersedia bagi Anda ketika menggunakan layanan kami.',
        item2: '<0 className="m-fs-12">Penyedia Pihak Ketiga:</0> Kami bekerja dengan penyedia pihak ketiga untuk membantu kami mengoperasikan, menyediakan, meningkatkan, memahami, menyesuaikan, mendukung, dan memasarkan layanan kami. Saat kami membagikan informasi dengan penyedia pihak ketiga, kami meminta mereka untuk menggunakan informasi Anda sesuai dengan instruksi dan persyaratan kami atau seizin Anda.'
      }
    },
    info8: {
      title: 'Persyaratan Layanan',
      list1: {
        title: 'Registrasi',
        content: 'Anda harus mendaftar untuk menggunakan layanan kami menggunakan data yang akurat serta memberikan nomor ponsel Anda saat ini. Anda setuju untuk menerima panggilan singkat (dari kami atau penyedia pihak ketiga kami) dengan kode OTP untuk mendaftar atau login ke layanan kami.'
      },
      list2: {
        title: 'Umur',
        content: 'Usia minimal untuk menggunakan layanan kami adalah 15 tahun (dengan ketentuan usia 15 tahun hingga 18 tahun masih dalam pengawasan/bimbingan dan memiliki izin dari orang tua atau wali). Selain usia minimum yang diwajibkan untuk menggunakan layanan kami berdasarkan hukum yang berlaku, jika Anda belum cukup umur untuk memiliki wewenang untuk menyetujui persyaratan kami di negara Anda, orang tua atau wali Anda harus menyetujui persyaratan kami atas nama Anda. Dengan menyetujui Kebijakan Privasi, Anda dan/atau orang tua, wali atau pengampu Anda (dalam hal Anda berusia di bawah 18 (delapan belas) tahun) mengakui bahwa Anda telah membaca dan memahami Kebijakan Privasi ini dan menyetujui segala ketentuannya.'
      },
      list3: {
        title: 'Perangkat Seluler dan Perangkat Lunak',
        content: 'Anda harus menyediakan perangkat seluler, perangkat lunak, dan koneksi data tertentu untuk menggunakan layanan kami, yang tidak kami sediakan. Selama Anda menggunakan layanan kami, Anda menyetujui untuk mengunduh dan menginstal pembaruan ke layanan kami, secara otomatis.'
      },
      list4: {
        title: 'Biaya dan Pajak',
        content: 'Anda bertanggung jawab atas semua paket data operator dan biaya serta pajak lain yang terkait dengan penggunaan layanan kami oleh Anda.'
      }
    },
    info9: {
      title: 'Mengelola Informasi Anda',
      list: {
        content: 'Jika Anda ingin mengelola atau mengubah informasi seperti nomor ponsel dan email Anda, terdapat beberapa cara yang memungkinkan Anda untuk melakukannya, yaitu sebagai berikut:',
        item1: 'Mengubah nomor ponsel Anda dengan menghubungi kami lewat email ke info@eurekaedutech.com.',
        item2: 'Anda juga dapat mengubah nama, provinsi, kota, nama sekolah, kelas, dan jurusan Anda kapan saja melalui aplikasi.'
      }
    },
    info10: {
      title: 'Perubahan Kebijakan Privasi Kami',
      content: 'Kami dapat mengubah atau merevisi Kebijakan Privasi. Setiap perubahan pada Kebijakan Privasi ini akan menjadi efektif ketika kami mengumumkan versi revisi melalui situs https://www.eurekaedutech.com/policy. Kami sangat menganjurkan Anda untuk mengakses situs kami secara berkala agar mengetahui perubahan-perubahan terbaru. Dengan terus mengakses dan menggunakan layanan Eureka Edutech, Anda dianggap telah menerima dan menyetujui segala perubahan.'
    },
    info11: {
      content: 'Jika Anda memiliki keraguan atau pertanyaan tentang Kebijakan Privasi ini, Anda dapat menghubungi kami di info@eurekaedutech.com. Jika Anda tidak setuju atau tidak dapat menerima praktik apapun yang diuraikan dalam Kebijakan Privasi ini, mohon disampaikan agar kami dapat meningkatkan kualitas layanan.'
    }
  },
  footer: {
    companyName: `PT. Edukasi Rekanan Anda`,
    companyAddress1: `Graha Satria Lt. 1. 201, Jl. RS. Fatmawati Raya No. 5,`,
    companyAddress2: `RT.1/RW.9, Daerah Khusus Ibukota Jakarta 12430`,
    downloadQuote: `Coba GRATIS Aplikasi Eureka Edutech`,
    features: {
      title: `Fitur`,
      feature1: `Aplikasi Eureka Edutech`,
      feature2: `Buku Panduan Asesmen Nasional (2022)`,
    },
    helps: {
      title: `Bantuan`,
      help1: `Artikel Terbaru`,
      help2: `Tentang Kami`,
      help3: `Kontak Kami`,
      help4: `Syarat & Ketentuan`,
      help5: `Kebijakan Privasi`,
    },
    contacts: {
      title: `Hubungi Kami`,
      phone: `08111451220`,
      email: `info@eurekaedutech.com`,
    },
    followUs: `Ikuti Kami`
  }
};
