import { useTranslation } from "react-i18next"

const Content1 = () => {
    const { t } = useTranslation()

    return(
        <div className="gradient-policy">
            <h5 className="content1Policy white-text fw-bold fs-30 m-fs-18 lh-160 text-center">{t('policy.title')}</h5>
        </div>
    )
}

export default Content1