import { useTranslation } from 'react-i18next';

const Content1 = () => {
    const { t } = useTranslation()

    return(
        <div className="content1ProductBook">
            <h5 className="text-center color-blue fw-bold fs-30 m-fs-16 lh-160">{t('products.book.longDescription.title')}</h5>
            <p className="fw-500 fs-16 m-fs-12 lh-160 text-grey">{t('products.book.longDescription.description')}</p>
        </div>
    )
}

export default Content1