import { useTranslation } from 'react-i18next';

const Content4 = () => {
    const { t } = useTranslation()

    return (
        <div className="content4ProductBook mx-auto d-block text-center mb-5 mt-5">
            <h6 className="text-white fw-bold fs-20 m-fs-8 lh-160">{t('products.book.question.title')}</h6>
            <h5 className="fs-30 m-fs-14 text-white fw-bold lh-160">{t('products.book.question.solution')}</h5> <br className="d-none d-sm-block" />
            <h6 className="fw-bold fs-20 m-fs-8 lh-160 text-white">{t('products.book.question.orderQuote')}</h6>
        </div>
    )
}

export default Content4