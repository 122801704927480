import { useEffect } from 'react';
import Footer from '../../components/Footer';
import Header from "../../components/Header";
import Description from './Description';
import Team from './Team';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='overflow-x-hidden'>
      <Header />
      <Description />
      <Team />

      <br></br>
      <br></br>


      <Footer />
    </div>
  )
}

export default About;