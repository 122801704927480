module.exports = {
  topmenu: {
    home: `Home`,
    about: `About`,
    products: {
      title: `Products`,
      application: {
        title: `Eureka Edutech App`,
        description: `Eureka Edutech builds a learning app that...`
      },
      book: {
        title: `National Assessment Handbook`,
        description: `Eureka Edutech builds a learning app that...`
      }
    },
    blog: `Blog`,
    download: `Download Application`,
    viewMore: `View more`
  },
  landing: {
    appName: `Eureka Edutech`,
    description: `National Assessment Preparation made easy with Eureka Edutech!`,
    subdesc: `<0>National Assessment Preparation made easy <br/> with Eureka Edutech!</0>`,
    benefits: {
      section1: {
        title: `Readiness`,
        description: `Be prepared to face the National Assessment through practice questions and exams provided in our materials.`
      },
      section2: {
        title: `Steadiness`,
        description: `Become a part of enhancing Indonesia's national PISA scores through preparation of the National Assessment.`
      },
      section3: {
        title: `Focus`,
        description: `The focus of preparation is to understand the concept of literacy and numeracy in the National Assessment according to the Assessment and Learning Center (Pusmenjar) indicators.`
      },
      section4: {
        title: `Comprehension`,
        description: `Not only by practice questions, it is also important  to understand learning concepts through comprehensive Literacy and Numeracy Materials.`
      },
    },
    reasons: {
      title: `Why Eureka Edutech?`,
      section1: {
        title: `Latest Curriculum`,
        description: `Our materials and question banks adapted to the latest curriculum from the Assessment and Learning Center (Pusmenjar)`
      },
      section2: {
        title: `Comprehensive Material`,
        description: `We provide the most complete material, sample questions, practice questions, and exams that improve understanding in preparation for the National Assessment`
      },
      section3: {
        title: `User-Friendly Application`,
        description: `Enhance your skills through our friendly and easy to use application`
      },
      section4: {
        title: `Detailed Discussion`,
        description: `We specifically prepare you for the National Assessment with the most focused and detailed materials and question banks`
      },
    },
    tutorials: {
      title: `Let's Start Using Eureka Edutech !`,
      step1: `Download the "Eureka Edutech" Application from the Play Store`,
      step2: `Login or Register for an account`,
      step3: `Choose your Preferred Learning Materials`,
      step4: `Materials, Practice Questions and Exams on each Topic of Study`,
      step5: `Daily Quizzes you can Complete Every Day !`,
    },
    faqs: {
      title: `What is the EUREKA Mobile application?`,
      question1: {
        title: `What is the EUREKA Mobile application ?`,
        description: `An application that can help students to learn material about literacy, numeracy, and science easily. The curriculum in this application refers to the main indicators tested in the 2022 national assessment to support the improvement of Indonesia's PISA ranking.`
      },
      question2: {
        title: `Who can use the EUREKA application?`,
        description: `Anyone can use EUREKA services to support learning activities and preparation for national assessments. Currently, the service is only available for the high school level.`
      },
      question3: {
        title: `How do I register for a EUREKA service?`,
        description: `For now, our service is only available through the Android version of the EUREKA application which can be downloaded via the Google Play Store.`
      },
      question4: {
        title: `Can those other than high school students use the EUREKA application?`,
        description: `The materials available on the EUREKA platform are designed to prepare high school students for the new national assessment in Indonesia. The platform for teachers is currently still in the development stage, however, teachers with an interest in teaching and learning activities may also access the EUREKA application.`
      },
      question5: {
        title: `What are the features of the EUREKA application?`,
        description: `The EUREKA application contains several features:
        Learning materials
        This feature contains a collection of learning modules as a guide to Practice questions
        Practice Questions
        This feature contains a collection of questions as practice materials to prepare students before conducting a simulation exam
        Exams
        This feature contains questions to measure students' ability and understanding of the numeracy and literacy materials that have been studied
        `
      },
      question6: {
        title: `How can I contact EUREKA's customer service?`,
        description: `For customer service, you can contact us through:
        Email		: info@eurekaedutech.com
        Twitter		: @eureka_edutech
        Instagram	: @eureka.edutech`
      },
      question7: {
        title: `How do I file a consumer complaint?`,
        description: `If you experience problems, you can directly contact us through the following:
      \n\nEmail : info@eurekaedutech.com`
      },
      question8: {
        title: `Can vocational students and college students use the EUREKA application?`,
        description: `Yes, because this is a learning application for all. Questions for the national assessment are also needed by all students.`
      },
    }
  },
  about: {
    title: `About`,
    descriptions: {
      description1: ` is an educational technology company that focuses on national assessments for students in Indonesia which was established under the laws of Indonesia in 2020 in South Jakarta. Eureka Edutech was founded by a group of educational activists with overseas PhD graduates consisting of various professional backgrounds such as national and international Olympic team coaches, entrepreneurs, literacy activists and educational consultants.`,
      description2: `Eureka Edutech wants to answer the challenges of national education holistically, especially those referring to the results of the PISA (OECD) ranking which is a reference for how well students in a country understand the ability to understand reading/reading, mathematics and science.`,
      description3: `With the issuance of REGULATION OF THE MINISTER OF EDUCATION, CULTURE, RESEARCH, AND TECHNOLOGY OF THE REPUBLIC OF INDONESIA, NUMBER 17 YEAR 2021, CONCERNING NATIONAL ASSESSMENT, a new chapter in the world of primary and secondary education in Indonesia begins. With the spirit of Freedom to Learn, the official National Examination or National Examination is replaced with a National Assessment approach.`,
      description4: `Eureka Edutech would like to underline the PerMen regarding the objectives of the National Assessment:`,
      description5: `Encouraging teachers to develop basic cognitive competencies as well as the character of students as a whole.`,
      description6: `Shows what should be the main goal of the school, namely the development of student competence and character.`,
      description7: `Provide an overview of the essential characteristics of effective schools to achieve these goals. Measures of cognitive competence as mentioned above include reading and numeracy literacy.`,
      description8: `Eureka Edutech develops learning applications to prepare students to become better learners in the field of literacy and numeracy, which is also beneficial for those who enter or are selected to take the Minimum Competency Assessment process through their respective schools. What can we present to our young generation who are still sitting and struggling in education besides the relevant question bank, which boosts their cognitive abilities, which in turn will shape the strength of our young generation to face a future with higher competitiveness.`,
    },
    teams: {
      title: `Our Team`,
      member1: {
        name: `Herman Huang, M.Sc., MBA`,
        position: `Founder`,
        description: `Holds a Master of Business degree from the IMD Business School, Switzerland. He has 16 years of experience as a technopreneur in several business sectors and contributes to developing young entrepreneurial abilities.`,
      },
      member2: {
        name: `AC Mahendra K Datu, Ph.D`,
        position: `Co-Founder`,
        description: `Holds a Ph.D at The National University of Singapore (NUS), SEAS Trade and Strategic Industry. Experienced as mentor on campus and corporate strategy. OSCI designer and innovation columnist in major media. He is also active as an author since 2009 on business and management, economics, finance, and human resource productivity.`,
      },
      member3: {
        name: `Herry Kwee, Ph.D`,
        position: `Director & Numeration Content Team`,
        description: `Holds a Ph.D in Physics from the College of William and Mary. More than 10 years of experience in the field of education. Active as the coach of the Indonesian physics olympiad team since 2000 as well as the academic team and jury of international and national olympic training/competitions.`,
      },
      member4: {
        name: `Hendra Kwee, Ph.D`,
        position: `Director & Numeration Content Team`,
        description: `He holds a Ph.D in Physics from the College of William and Mary. 19 years of experience in the field of education. Active as a team leader for the Indonesian physics olympiad and other olympiads and has won medals in every competition that the trainees participated in.`,
      },
      member5: {
        name: `Jong Anly Tan, Ph.D`,
        position: `Numeration Content Team`,
        description: `Holds a Ph.D in Physics from the College of William and Mary. 11 years of experience as a leader, judge and trainer in several national and international physics olympiads. He is currently active as a coach for the Indonesian Physics Olympiad team since 2009.`,
      },
      member6: {
        name: `Zainul Abidin, Ph.D`,
        position: `Numeration Content Team`,
        description: `Obtained his Ph.D in Theoretical Physics from the College of William and Mary. 9 years experience as a coach and team leader of the Indonesian Delegation at the Asian Physics Olympiad. Achievements include a Silver Medal at the 1st Asian Physics Olympiad in Karawaci, Indonesia 2000 and an Honorable Mention at the 31st International Physics Olympiad in Leicester, England 2000.`,
      },
      member7: {
        name: `ign. Yophiandi Kurniawan M.Sos`,
        position: `Literacy Content Team`,
        description: `20 years of experience in journalism and literacy. Has worked in several leading news portals in Indonesia.`,
      },
      member8: {
        name: `Elvita Sari`,
        position: `Project Leader`,
        description: `Earned a bachelor of science (S.Si) degree in 2018 and has been involved for 6 years in the world of education development. In addition, she is also experienced in marketing, research, and project management in communities and startups, especially education and human development.`,
      },
      member9: {
        name: `Iftihal Muslim Rahman`,
        position: `Marketing Executive`,
        description: `6 years experience in journalism. A public speaker, poetry and fiction writer, and beauty content creator.`,
      },
      member10: {
        name: `Cahyarani Paramesti`,
        position: `Marketing Executive`,
        description: `3 years experience as pianist, painter and dancer, head of artistic and publication division, graphic design and content writer.`,
      },
      member11: {
        name: `Faturachman Asyari Oktavian`,
        position: `UI/UX Designer`,
        description: `He holds a Bachelor of Engineering degree in Computer Engineering, Diponegoro University, Semarang. 3 years experience in UI and UX Design.`,
      },
      member12: {
        name: `Putri Husnul Aprilia`,
        position: `Sales`,
        description: `Earned a bachelor's degree in social (S.sos) in 2019 and has two years of experience as a management and Business Development consultant assistant.`,
      },
      member13: {
        name: `Hadistian S.Pd`,
        position: `Literacy Content Team`,
        description: `He earned his bachelor's degree in Geography Education at the State University of Jakarta. Has been a contributor to student rubrics and opinions in Koran Sindo and Media Indonesia.`,
      },
      member14: {
        name: `Muhammad Khairul Ihsan S.Si`,
        position: `Numeration Content Team`,
        description: `He holds a bachelor of science degree in Mathematics at Hasanuddin University. 10 years experience in teaching mathematics and national Olympic coach.`,
      },
      member15: {
        name: `Dimas Widianto Ramadhan S.Si`,
        position: `Numeration Content Team`,
        description: `He holds a bachelor of science degree in Instrumentation Physics at Syarif Hidayatullah State Islamic University, Jakarta. He was a physics laboratory assistant at the Integrated Laboratory Center of UIN Jakarta.`,
      },
      member16: {
        name: `Fadella Amirah Siregar`,
        position: `Full Stack Developer`,
        description: `Experienced as a full stack web developer with skills: Web Development, Backend Development, Web Socket, Cloud Computing, Git / Github, Databases.`,
      },
      member17: {
        name: `Daniel Saputra`,
        position: `Full Stack Developer`,
        description: `Experienced as a full stack developer with skills: Backend development, Web Applications, Data scraping, Cloud Computing, and IT Automation.`,
      },
      member18: {
        name: `Ikrima Amanda Wulandari`,
        position: `Android Developer`,
        description: `2 years experience as an android application developer with skills: Kotlin, API, XML, Android studio, Git / Github, MVVM, MVP, Firebase Realtime DB, NodeJS, ExpressJS, MySQL.`,
      },
    }
  },
  products: {
    application: {
      title: `Eureka Edutech App`,
      description1: `Learn Anywhere and Anytime with`,
      description2: `Eureka Edutech.`,
      downloadQuote: `Try the App, it's FREE`,
      reasons: {
        title: `Why choose the Eureka Edutech App ?`,
        description: `Eureka Edutech builds a learning application that can help students study and simulate real-time national assessments with a question bank that has been formulated in such a way. One of the superior features of this application is that students can study literacy and numeracy material directly, which is equipped with sample questions and discussions, and participants can perform exam simulations according to the chosen topic. In addition, there is a daily question feature to hone students' memory every day and an activities feature to refresh activities that have been completed previously. Can't wait to explore our application? Let's download the application now, it's FREE!`,
      },
      features: {
        title: `What Learning Features Can You Use ?`,
        feature1: `Thousands of Literacy and Numeracy Questions`,
        feature2: `Test Your Understanding To Be More Confident`,
        feature3: `Practice Questions That Simplifies Learning`,
        feature4: `Your Progress Will Be Stored`,
        feature5: `Various Interesting Articles To Add To Your Insight`,
        feature6: `Save Your Favorite Topics`,
      },
      subscriptions: {
        title: `Benefits of Subscribing to Eureka Edutech`,
        benefitTitle: `Includes`,
        choose: `Choose Package`,
        downloadQuote: `Let's download the application now, FREE!`,
        recommended: 'Recommended',
        plan1: {
          title: `Starter Pack`,
          price: `IDR 120.000`,
          discountPrice: `IDR 95.000`,
          duration: `1 month`,
          benefit1: `Unlimited access to all materials`,
          benefit2: `Free practice questions and discussions`,
          benefit3: `Hone your skills in daily quizzes freely`,
          benefit4: `Access 1 Month exam simulation`,
          benefit5: `Access the Kumpas Total webinar National Assessment for 1 month`,
          benefit6: `Interesting Merchandises`,
        },
        plan2: {
          title: `Super Pack`,
          price: `IDR 1.200.000`,
          discountPrice: `IDR 800.000`,
          duration: `12 month`,
          benefit1: `Unlimited access to all materials`,
          benefit2: `Free practice questions and discussions`,
          benefit3: `Hone your skills in daily quizzes freely`,
          benefit4: `Access 12 Month exam simulation`,
          benefit5: `Access the Kumpas Total Assessment webinar for 12 Month`,
          benefit6: `Free 15% National Assessment guidebook`,
          benefit7: `Free consultation 24 questions with tutor`,
          benefit8: `Interesting Merchandises`,
        },
        plan3: {
          title: `Basic Pack`,
          price: `IDR 443.000`,
          discountPrice: `IDR 399.000`,
          duration: `6 month`,
          benefit1: `Unlimited access to all materials`,
          benefit2: `Free practice questions and discussions`,
          benefit3: `Hone your skills in daily quizzes freely`,
          benefit4: `Access 6 Month exam simulation`,
          benefit5: `Access the Kumpas Total webinar National Assessment for 6 months`,
          benefit6: `Interesting Merchandise`,
          benefit7: `Free 10% National Assessment guidebook`,
        }
      }
    },
    book: {
      title: `2022 National Assessment Handbook`,
      description: `Preparing for a national assessment, learning literacy and numeracy materials with Eureka Edutech!`,
      orderButton: `Order now !`,
      longDescription: {
        title: `Supporting Your Learning Material`,
        description: `As a support and help with limitations in online learning, we also provide learning package books regarding the materials tested in the national assessment that have been adapted to the main indicators listed in the Assessment and Learning Center (Pusmenjar) guide. Currently, this book is intended for high school students in Indonesia to help prepare for the National Assessment in 2022. This book is equipped with a guide to learning materials along with sample questions and discussions, as well as two exam simulation packages and answer keys. So, do you want to study together? Let's Pre-Order now!`,
      },
      benefits: {
        title: `What Will You Get ?`,
        benefit1: `National Assessment Materials`,
        benefit2: `Exercises`,
        benefit3: `Comprehensive Discussion`,
        benefit4: `Exam Simulation`,
      },
      question: {
        title: `Want to learn about the 2022 National Assessment learning materials ?`,
        solution: `2022 National Assessment Handbook`,
        orderQuote: `Order and learn with Eureka Edutech !`,
      }
    }
  },
  contact: {
    title: 'Contact Us',
    subtitle: 'Contact Us',
    desc: 'Fill in the following form if you have questions, complaints, proposals for cooperation/sponsorship. We will get back to you as soon as possible.',
    form: {
      notes: 'All fields are required',
      name: 'Name',
      email: 'Email Address',
      phone: 'Phone Number',
      subject: 'Subject',
      message: 'Message Content',
      sendBtn: 'Send'
    }
  },
  terms: {
    title: 'Terms & Conditions',
    subtitle: 'Terms and Conditions of Use of Eureka Edutech Application',
    desc: {
      paragraph1: '<0 className="m-fs-12">EUREKA</0> is a product developed, owned and managed by PT. Edukasi Rekanan Anda. <0 className="m-fs-12">EUREKA</0> is an educational service in the form of information in the form of a software application ("Platform").',
      paragraph2: 'By downloading, installing, and/or using this Platform, in other words you agree that you have read, understood, and agreed to all information, terms and conditions of use. The Terms and Conditions are made, with the aim of indicating that there is a legal agreement between the User and <0 className="m-fs-12">EUREKA</0>. However, please note that the terms and conditions and Privacy Policy will change from time to time and the results of the changes that will occur will be notified to you when you access the Platform.',
      paragraph3: 'If you wish to delete an account on this platform, you can submit an account deletion request. Please fully understand the terms and conditions of use before accessing this platform. By pressing the check mark means that you agree to the Terms and Conditions of use of this application.',
    },
    requirements: {
      title: 'General Requirements',
      list1: {
        item1: 'All substitute words under these Terms and Conditions for "You" refer to the Application User ("User"), for "EUREKA" and "We" refer to PT. Edukasi Rekanan Anda.',
        item2: 'Internet connection is the main requirement needed to be able to access all existing services. All costs incurred as a result of using the application are the sole responsibility of the user.',
        item3: {
          content: 'Some of the features in this application are:',
          list1: 'Reading articles',
          list2: 'Material and question bank',
          list3: 'User activities',
          list4: '<0 className="m-fs-12">Any other services that we may add from time to time</0>'
        },
        item4: 'The platform is only a means to make it easier to use educational applications that have been developed.',
        item5: 'We are an educational technology company. We do not employ Service Providers, and we are not responsible for the acts, carelessness, omissions, and/or negligence of Service Providers. The Platform is an online information and communication service provided by EUREKA.'
      }
    },
    termsOfUse: {
      title: 'Application Terms of Use',
      list: {
        item1: 'By accessing the platform/using the service, you represent and warrant that your personal data published or displayed on the platform is accurate. If the data provided is inaccurate, we reserve the right to cancel your account or delete your account in our database.',
        item2: 'By downloading the platform, you agree to comply with all guidelines, notices and policies regarding the use of services to access the platform and you agree to carry out any activities related to the service in a good manner.',
        item3: 'Access to the platform is protected by a password. Then you represent and warrant not to give your account password to anyone who can access the platform with your personal data. If you authorize other people to access the platform, we are not responsible for any use that violates the rules because the application records the account that belongs to you. Unless you provide information regarding your account.',
        item4: 'You acknowledge that you will use this platform only to obtain services. You are not allowed to abuse the platform or modify it in any way. We reserve the right to close your account or not be allowed to re-register with your personal data if you carry out activities that violate our rules.'
      }
    },
    features: {
      title: 'Services and Features',
      list1: {
        title: 'Reading Articles',
        item1: 'This feature facilities users with articles about literacy and numeracy',
        item2: 'You can found out the latest articles from EUREKA and interesting programs from EUREKA'
      },
      list2: {
        title: 'Materials and Question Bank',
        item1: 'This feature contains a collection of subject matter as well as discussion tips and tricks in solving problems',
        item2: 'This feature also contains a collection of question banks as practice material with various types of questions'
      },
      list3: {
        title: 'User Activity',
        item1: 'This feature tracks user activity while using the EUREKA Platform'
      }
    },
    liability: {
      title: 'Limitation of Liability',
      item1: `The application is not responsible for the condition of the user's position.`,
      item2: `The application is not responsible for the actions that the user takes after using the application's feature services.`,
      item3: `The application is not responsible for the costs incurred by the user after using the application's feature services.`,
      item4: `The application is not responsible for the discrepancy of the material provided on the Platform with user expectations.`
    },
    notification: {
      title: 'Notification',
      content: 'All notices and/or requests for information to and/or related to us will be processed in writing and sent to our address at <0 className="m-fs-12">Graha Satria 1 Suite 203, Jalan RS Fatmawati No. 5 RT.01/RW.09, Cilandak Barat, South Jakarta, Jakarta 12430</0> or our email address at : <0 className="m-fs-12">eureka.learningplatform@gmail.com</0> or call center at <0 className="m-fs-12">(021) 3451737</0>.'
    },
    security: {
      title: 'Application Security',
      content: `We strive to keep our application system running and functioning properly. You understand that today's technology is very capable of infiltrating our systems. Therefore any messages or personal information may be hacked easily. Therefore, we as the creators and owners always carry out security updates for the system in order to prevent criminal acts committed by irresponsible parties that can harm us and you.`
    },
    onesignal: {
      title: 'Onesignal',
      content: `We use the user's email and/or phone number data to send notifications to users.`
    },
    closing: {
      title: 'Closing',
      list: {
        item1: 'The relationship that occurs between users and us is not a relationship in the form of an agency, partnership, joint venture, employee-company or franchisee-franchisor but an independent relationship.',
        item2: 'The title in the Terms of Use is made only as a reference as the terms and conditions of this application.',
        item3: 'We have the right to investigate and claim our rights for any violation of these Terms of Use to the extent permitted and permitted by law. You hereby acknowledge that we have the right to monitor access to the use of the site and application to ensure compliance with these Terms of Use, or to comply with applicable regulations or orders or requirements from courts, administrative agencies or other government bodies.',
        item4: 'Application users are strictly not allowed to launder money and/or use money that has sources that are legally illegal. We reserve the right to report all forms of transactions that are deemed to be suspected of being a money laundering crime to the authorities.',
        item5: 'If in the future manipulation/fraud actions are found, such as closing or canceling accounts, withdrawing subsidies, withdrawing cashback, canceling transactions, withholding funds, and other things if there are indications of fraud, money laundering or violations committed by users, we reserve the right and without giving prior notice to take the necessary actions in accordance with the applicable legal provisions in the territory of the Republic of Indonesia.'
      }
    }
  },
  policy: {
    title: 'Privacy Policy',
    paragraph1: 'is committed to respecting and protecting your privacy. We understand how important your personal data is and we will not share it with other people or third parties without your permission. Please read the following Privacy Policy to learn more about how we will live up to our promises to you.',
    paragraph2: 'This policy describes how we collect, use and disclose your personal data that we obtain when you use Eureka Edutech. By continuing to use or access our applications, you are accepting the practices described in this Policy.',
    info1: {
      title: 'Information We Access and Collect',
      paragraph1: '<0 className="m-fs-12">Account Information:</0> By registering with the application at Eureka Edutech, you have provided a mobile number. Then, you have given us authorization to access your phone calls so that you can verify your account and make a password change if you forget your password via a short call to get an OTP (One Time Password). Other information such as name, email, telephone number, province, city, and school address may also be viewed and stored by us.',
      paragraph2: '<0 className="m-fs-12">Data from Mobile Device Settings:</0> We obtain information that you allow us to receive such as your phone call access and location to provide features of our services.'
    },
    info2: {
      title: 'Automatically Collected Information',
      paragraph1: '<0 className="m-fs-12">Usage Information and Logs:</0> We collect service, diagnostic and performance related information. This includes information about your activity (such as how you use our application services, how you use our website services, etc.), log files, diagnostic reports, crash data, websites, and performance reports.',
      paragraph2: '<0 className="m-fs-12">Mobile Device and Connection Information:</0> We collect specific information about your device when installing, accessing, or using our services. This includes information such as hardware model, operating system, browser information, IP address, mobile network information including mobile phone number, and device identifier.',
      paragraph3: '<0 className="m-fs-12">Cookies:</0> We use cookies to operate, including to provide our web-based services. We also use it to improve your experience by understanding how our services are used, and tailoring our services to suit you. We may use cookies and similar technologies on our website to collect information such as date and time of visit, geographic location, pages viewed, IP address, links to or from any page, browser type and version, operating system, referral source and time of day. spent on our website.',
      paragraph4: '<0 className="m-fs-12">Status Information:</0> We collect information about changes to parts of your profile, such as whether you have changed your profile information in the edit profile tool.'
    },
    info3: {
      title: 'Third Party Information',
      paragraph1: '<0 className="m-fs-12">Third Party Providers:</0> We work with third party providers to provide services on our behalf, to improve services or to assist us in analyzing how our services are used. We may receive information from third party providers depending on your policies and account settings with applicable third parties. We have no control over and are not responsible for the use or disclosure of your personal information by third parties.'
    },
    info4: {
      title: 'User Data',
      paragraph1: 'We store information on servers located in Indonesia. We work with third parties to provide a variety of technology-related services. Your problem solving data will not be shared with any other company or service. Your data is securely stored and not used for any purposes other than the development of our applications and services.'
    },
    info5: {
      title: 'Confidentiality of Information and Consumer Services',
      item1: `Eureka Edutech will not provide or distribute users' personal information obtained through the use of the application to third parties. However, this does not include when the information is requested by the relevant authorities, including: the Indonesian National Police, certain Civil Servant Officers and the Standards of Commission Prosecutor's Office applying for information, or in other cases through legal procedures.`,
      item2: 'Eureka Edutech must respond quickly to all user complaints about the service. If a brief description of the complaint is not sufficient, Eureka Edutech will post or display an explanation on the application screen or via email and will plan a system upgrade.',
      item3: 'Eureka Edutech relies on legislation related to Intellectual Property Rights (IPR) and user privacy data.'
    },
    info6: {
      title: 'How We Use Information',
      list: {
        content: 'We use all information that can assist us in improving the following:',
        item1: '<0 className="m-fs-12">Services:</0> We operate and provide our services, including customer service, and also improve, improve and customize our services. From these activities, we analyze existing information to evaluate and improve our services, research, develop and test new services and features. We also use your information to respond to you when you contact us.',
        item2: '<0 className="m-fs-12">Safety and Security:</0> We verify accounts and activity, and promote safety and security on and off our services, such as by investigating suspicious activity or violations of our terms, and to ensure that our services are used legally.'
      }
    },
    info7: {
      title: 'Shared Information',
      content: 'You share your information when you use Eureka Edutech to change passwords and solve problems, and we provide your information to help us operate, provide, improve, understand, customize, support and market our services.',
      list: {
        item1: '<0 className="m-fs-12">Account Information:</0> Your name, mobile number, email, state, city, school name, class and major may be available to you when using our services.',
        item2: '<0 className="m-fs-12">Third Party Providers:</0> We work with third party providers to help us operate, provide, improve, understand, customize, support and market our services. When we share information with third party providers, we ask them to use your information in accordance with our instructions and terms or with your permission.'
      }
    },
    info8: {
      title: 'Terms of Service',
      list1: {
        title: 'Registration',
        content: 'You must register to use our services using accurate data as well as provide your current mobile number. You agree to receive a short call (from us or our third party provider) with an OTP code to register for or login to our services.'
      },
      list2: {
        title: 'Age',
        content: 'The minimum age to use our services is 15 years (provided that ages 15 to 18 are still under supervision/guidance and have permission from their  parents or guardians). In addition to the minimum age required to use our services under applicable law, if you are not of age to have the authority to agree to our terms in your country, your parent or guardian must agree to our terms on your behalf. By agreeing to the Privacy Policy, you and/or your parent, guardian or guardian (in case you are under the age of 18 (eighteen) years) acknowledge that you have read and understood this Privacy Policy and agree to all of its terms.'
      },
      list3: {
        title: 'Mobile Devices and Software',
        content: 'You must provide certain mobile devices, software and data connections to use our services, which we do not provide. As long as you use our service, you agree to automatically download and install updates to our service.'
      },
      list4: {
        title: 'Fees and Taxes',
        content: 'You are responsible for all carrier data plans and other fees and taxes associated with your use of our services.'
      }
    },
    info9: {
      title: 'Managing Your Information',
      list: {
        content: 'If you want to manage or change information such as your mobile number and email, there are several possible ways you can do this, including the following:',
        item1: 'Change your mobile number by contacting us via email to info@eurekaedutech.com.',
        item2: 'You can also change your name, province, city, school name, class and major at any time via the app.'
      }
    },
    info10: {
      title: 'Changes to Our Privacy Policy',
      content: `We may change or revise the Privacy Policy. Any changes to this Privacy Policy will become effective when we announce the revised version through the https://eureka.my.id/kebijakan-privasi site. We strongly encourage you to access our site regularly to be aware of the latest changes. By continuing to access and use Eureka Edutech's services, you are deemed to have accepted and agreed to all changes.`
    },
    info11: {
      content: 'If you have any doubts or questions about this Privacy Policy, you can contact us at info@eurekaedutech.com. If you do not agree with or do not accept any of the practices described in this Privacy Policy, please let us know so we can improve the quality of our services.'
    }
  },
  footer: {
    companyName: `PT. Edukasi Rekanan Anda`,
    companyAddress1: `Graha Satria Lt. 1. 201, Jl. RS. Fatmawati Raya No. 5,`,
    companyAddress2: `RT.1/RW.9, Special Capital Region of Jakarta 12430`,
    downloadQuote: `Try the FREE Eureka Edutech App`,
    features: {
      title: `Features`,
      feature1: `Eureka Edutech App`,
      feature2: `National Assessment Guidebook (2022)`
    },
    helps: {
      title: `Help`,
      help1: `Latest Articles`,
      help2: `About Us`,
      help3: `Contact Us`,
      help4: `Terms & Conditions`,
      help5: `Privacy Policy`,
    },
    contacts: {
      title: `Contact Us`,
      phone: `08111451220`,
      email: `info@eurekaedutech.com`
    },
    followUs: `Follow Us`
  }
}