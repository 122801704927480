import TeamMobile from './Team-mobile'
import { Carousel } from 'react-bootstrap'

import BtnCarousel from '../../assets/images/Carousel-btn.svg'
import { useTranslation } from 'react-i18next';
import useWindowSize from "../../helpers/screen-size";

// temporary fix, refactor later
const Herman = "https://eureka-edutech.s3.ap-southeast-1.amazonaws.com/public/members/Herman.svg"
const Mahendra = "https://eureka-edutech.s3.ap-southeast-1.amazonaws.com/public/members/Mahendra.svg"
const Elvita = "https://eureka-edutech.s3.ap-southeast-1.amazonaws.com/public/members/Elvita.svg"
const Iftihal = "https://eureka-edutech.s3.ap-southeast-1.amazonaws.com/public/members/Iftihal.svg"
const Dimas = "https://eureka-edutech.s3.ap-southeast-1.amazonaws.com/public/members/Dimas.svg"
const Daniel = "https://eureka-edutech.s3.ap-southeast-1.amazonaws.com/public/members/Daniel.svg"
const Fadella = "https://eureka-edutech.s3.ap-southeast-1.amazonaws.com/public/members/Fadella.svg"
const Ikrima = "https://eureka-edutech.s3.ap-southeast-1.amazonaws.com/public/members/Ikrima.svg"
const Fatur = "https://eureka-edutech.s3.ap-southeast-1.amazonaws.com/public/members/Fatur.svg"
const Putri = "https://eureka-edutech.s3.ap-southeast-1.amazonaws.com/public/members/Putri.svg"
const Ihsan = "https://eureka-edutech.s3.ap-southeast-1.amazonaws.com/public/members/Ihsan.svg"
const Cahyarani = "https://eureka-edutech.s3.ap-southeast-1.amazonaws.com/public/members/Cahyarani.svg"

const Team = () => {
    const { t } = useTranslation()

    const [, width] = useWindowSize()
    const isMobile = width <= 760

    const directionButtons = (direction) => {
        return (
            <span
                aria-hidden="true"
                className={direction === "Next" ? "button-next" : "button-prev"}
            >
                <img src={BtnCarousel} alt="next" />
            </span>
        )
    }

    if (isMobile) {
        return (
            <TeamMobile />
        )
    } else {
        return (
            <div id="team" className='mb-4'>
                <div id="text-team" className="text-center lh-160 fs-30 fw-bold color-blue">{t('about.teams.title')}</div>
                <Carousel
                    nextLabel={"Next"}
                    prevLabel={"Previous"}
                    nextIcon={directionButtons("Next")}
                    prevIcon={directionButtons("Previous")}
                    interval={null}>
                    {/* 1 */}
                    <Carousel.Item>
                        <div className='d-flex flex-row justify-content-center mt-5'>
                            {/* Card 1 */}
                            <div className="card mx-5">
                                <img id='team-img' src={Herman} className="mx-auto d-block mt-4" alt="Herman Huang" />
                                <div className="card-body text-center">
                                    <h5 className='color-blue fw-bold'>{t('about.teams.member1.name')}</h5>
                                    <h6 className='color-blue fs-14 lh-17'>{t('about.teams.member1.position')}</h6> <br />
                                    <p className="card-text text-grey lh-17 fs-14">{t('about.teams.member1.description')}</p>
                                </div>
                            </div>
                            {/* Card 2 */}
                            <div className="card mx-5">
                                <img id='team-img' src={Mahendra} className="mx-auto d-block mt-4" alt="Mahendra" />
                                <div className="card-body text-center">
                                    <h5 className='color-blue fw-bold'>{t('about.teams.member2.name')}</h5>
                                    <h6 className='color-blue fs-14 lh-17'>{t('about.teams.member2.position')}</h6> <br />
                                    <p className="card-text text-grey lh-17 fs-14">{t('about.teams.member2.description')}</p>

                                </div>
                            </div>
                            {/* Card 3 */}
                            <div className="card mx-5">
                                <img id='team-img' src={Elvita} className="mx-auto d-block mt-4" alt="Elvita" />
                                <div className="card-body text-center">
                                    <h5 className='color-blue fw-bold'>{t('about.teams.member8.name')}</h5>
                                    <h6 className='color-blue fs-14 lh-17'>{t('about.teams.member8.position')}</h6> <br />
                                    <p className="card-text text-grey lh-17 fs-14">{t('about.teams.member8.description')}</p>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    {/* 2 */}
                    <Carousel.Item>
                        <div className='d-flex flex-row justify-content-center mt-5'>
                            {/* Card 1 */}
                            <div className="card mx-5">
                                <img id='team-img' src={Cahyarani} className="mx-auto d-block mt-4" alt="Cahyarani" />
                                <div className="card-body text-center">
                                    <h5 className='color-blue fw-bold'>{t('about.teams.member10.name')}</h5>
                                    <h6 className='color-blue fs-14 lh-17'>{t('about.teams.member10.position')}</h6> <br />
                                    <p className="card-text text-grey lh-17 fs-14">{t('about.teams.member10.description')}</p>
                                </div>
                            </div>
                            {/* Card 2 */}
                            <div className="card mx-5">
                                <img id='team-img' src={Fatur} className="mx-auto d-block mt-4" alt="Fatur" />
                                <div className="card-body text-center">
                                    <h5 className='color-blue fw-bold'>{t('about.teams.member11.name')}</h5>
                                    <h6 className='color-blue fs-14 lh-17'>{t('about.teams.member11.position')}</h6> <br />
                                    <p className="card-text text-grey lh-17 fs-14">{t('about.teams.member11.description')}</p>
                                </div>
                            </div>
                            {/* Card 3 */}
                            <div className="card mx-5">
                                <img id='team-img' src={Putri} className="mx-auto d-block mt-4" alt="Putri" />
                                <div className="card-body text-center">
                                    <h5 className='color-blue fw-bold'>{t('about.teams.member12.name')}</h5>
                                    <h6 className='color-blue fs-14 lh-17'>{t('about.teams.member12.position')}</h6> <br />
                                    <p className="card-text text-grey lh-17 fs-14">{t('about.teams.member12.description')}</p>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    {/* 3 */}
                    <Carousel.Item>
                        <div className='d-flex flex-row justify-content-center mt-5 ms-5'>
                            {/* Card 1 */}
                            <div className="card mx-5">
                                <img id='team-img' src={Ihsan} className="mx-auto d-block mt-4" alt="Iftihal" />
                                <div className="card-body text-center">
                                    <h5 className='color-blue fw-bold'>{t('about.teams.member14.name')}</h5>
                                    <h6 className='color-blue fs-14 lh-17'>{t('about.teams.member14.position')}</h6> <br />
                                    <p className="card-text text-grey lh-17 fs-14">{t('about.teams.member14.description')}</p>
                                </div>
                            </div>
                            {/* Card 2 */}
                            <div className="card mx-5">
                                <img id='team-img' src={Iftihal} className="mx-auto d-block mt-4" alt="Iftihal" />
                                <div className="card-body text-center">
                                    <h5 className='color-blue fw-bold'>{t('about.teams.member9.name')}</h5>
                                    <h6 className='color-blue fs-14 lh-17'>{t('about.teams.member9.position')}</h6> <br />
                                    <p className="card-text text-grey lh-17 fs-14">{t('about.teams.member9.description')}</p>
                                </div>
                            </div>
                            {/* Card 3 */}
                            <div className="card mx-5">
                                <img id='team-img' src={Dimas} className="mx-auto d-block mt-4" alt="Dimas" />
                                <div className="card-body text-center">
                                    <h5 className='color-blue fw-bold'>{t('about.teams.member15.name')}</h5>
                                    <h6 className='color-blue fs-14 lh-17'>{t('about.teams.member15.position')}</h6> <br />
                                    <p className="card-text text-grey lh-17 fs-14">{t('about.teams.member15.description')}</p>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    {/* 4 */}
                    <Carousel.Item>
                        <div className='d-flex flex-row justify-content-center mt-5'>
                            {/* Card 1 */}
                            <div className="card mx-5">
                                <img id='team-img' src={Fadella} className="mx-auto d-block mt-4" alt="Fadella" />
                                <div className="card-body text-center">
                                    <h5 className='color-blue fw-bold'>{t('about.teams.member16.name')}</h5>
                                    <h6 className='color-blue fs-14 lh-17'>{t('about.teams.member16.position')}</h6> <br />
                                    <p className="card-text text-grey lh-17 fs-14">{t('about.teams.member16.description')}</p>
                                </div>
                            </div>
                            {/* Card 2 */}
                            <div className="card mx-5">
                                <img id='team-img' src={Daniel} className="mx-auto d-block mt-4" alt="Daniel" />
                                <div className="card-body text-center">
                                    <h5 className='color-blue fw-bold'>{t('about.teams.member17.name')}</h5>
                                    <h6 className='color-blue fs-14 lh-17'>{t('about.teams.member17.position')}</h6> <br />
                                    <p className="card-text text-grey lh-17 fs-14">{t('about.teams.member17.description')}</p>
                                </div>
                            </div>
                            {/* Card 3 */}
                            <div className="card mx-5">
                                <img id='team-img' src={Ikrima} className="mx-auto d-block mt-4" alt="Ikrima" />
                                <div className="card-body text-center">
                                    <h5 className='color-blue fw-bold'>{t('about.teams.member18.name')}</h5>
                                    <h6 className='color-blue fs-14 lh-17'>{t('about.teams.member18.position')}</h6> <br />
                                    <p className="card-text text-grey lh-17 fs-14">{t('about.teams.member18.description')}</p>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </div>
        )
    }
}

export default Team