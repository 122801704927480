import Footer from '../../components/Footer';
import Header from "../../components/Header";
import Maps from './Maps'
import Content1 from './Content-1';
import { useEffect } from 'react';

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return(
        <div className='overflow-x-hidden'>
            <Header />
            <Content1 />
            <Maps />

            <br /> <br /> <br /> <br />
            <br className='d-none d-sm-block' /> <br className='d-none d-sm-block' /> <br className='d-none d-sm-block' /> <br className='d-none d-sm-block' />


            <Footer />

        </div>
    )
}

export default Contact