const XpertAbout = () => {
  return (
    <>
      <h5 className='text-center fs-24 lh-30 fw-700 text-navy mt-5 mb-4'>About Eureka Xpert</h5>
      <div className="xpertAbout">
        <div className="fw-500 fs-16 lh-160 text-grey">
          Eureka Xpert offers professional skills and talent development for corporates, startups, and universities, with topics such as corporate negotiation, power communication, productivity challenge, and digital skills preparation. Was launched in Nov 2022.
        </div>
      <br />
      <br />
        <h5 className="dark-text fw-700 fs-20 lh-25">Our Objective</h5>
        <ul className="fw-500 fs-16 lh-160 dark-text mt-3 mb-5">
          <li className="mt-4">Prepare the young generation to be involved in the professional world</li>
          <li className="mt-4">Improve the digital skills of employees in the corporate world</li>
          <li className="mt-4">Help analyze human resource needs in the company</li>
          <li className="mt-4">Encourage talent to quickly adapt to digital advances</li>
        </ul>
      </div>
    </>
  )
}

export default XpertAbout;