import Content3Mobile from "./Content-3-mobile";
import { BsCheckCircleFill } from "react-icons/bs";
import Recommend from '../../../assets/images/Recommend.svg'
import { useTranslation } from 'react-i18next';
import useWindowSize from "../../../helpers/screen-size";

const Content3 = () => {
    const { t } = useTranslation()

    const [ , width ] = useWindowSize()
    const isMobile = width <= 1000

    if(isMobile) {
        return(
            <Content3Mobile />
        )
    }else {
        return(
            <div>
                <h6 className="text-center color-blue fw-bold fs-30 lh-160 my-5">{t('products.application.subscriptions.title')}</h6>
                <div className="d-flex justify-content-center">
                    {/* Starter Pack */}
                    <div className="starter-pack">
                        <h6 className="fw-bold fs-22 lh-160 dark-text text-center mb-4">{t('products.application.subscriptions.plan1.title')}</h6>
                        <div className="fw-bold line-through fs-16 lh-160 dark-text">{t('products.application.subscriptions.plan1.price')}</div>
                        <h5 className="text-orange fw-bold d-inline-block fs-30 lh-160 mb-4">{t('products.application.subscriptions.plan1.discountPrice')}</h5>
                        <small className="dark-text fw-bold fs-16 lh-160"> / {t('products.application.subscriptions.plan1.duration')}</small>
                        <h5 className="fw-bold fs-16 lh-160 dark-text">{t('products.application.subscriptions.benefitTitle')} :</h5>
                        {/* 1 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                            {t('products.application.subscriptions.plan1.benefit1')}
                            </div>
                        </div>
                        {/* 2 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                                {t('products.application.subscriptions.plan1.benefit2')}
                            </div>
                        </div>
                        {/* 3 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                                {t('products.application.subscriptions.plan1.benefit3')}
                            </div>
                        </div>
                        {/* 4 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                                {t('products.application.subscriptions.plan1.benefit4')}
                            </div>
                        </div>
                        {/* 5 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                                {t('products.application.subscriptions.plan1.benefit5')}
                            </div>
                        </div>
                        {/* 6 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                                {t('products.application.subscriptions.plan1.benefit6')}
                            </div>
                        </div>
                        {/* Button */}
                        <a className="text-decoration" href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" target="_blank" rel="noreferrer">
                            <button className="mx-auto d-block btnAppEureka mt-5 py-2 text-orange fw-bold">{t('products.application.subscriptions.choose')}</button>
                        </a>
                    </div>
                    {/* Super Pack */}
                    <div className="super-pack mx-5">
                        <h6 className="fw-bold fs-22 lh-160 dark-text text-center mb-4">{t('products.application.subscriptions.plan2.title')}</h6>
                        <img src={ Recommend } alt='Eureka' className="my-4" />
                        <div className="super-pack-padding fw-bold line-through fs-16 lh-160 dark-text">{t('products.application.subscriptions.plan2.price')}</div>
                        <h5 className="super-pack-padding text-orange fw-bold d-inline-block fs-30 lh-160 mb-4">{t('products.application.subscriptions.plan2.discountPrice')}</h5>
                        <small className="red-text fw-bold fs-16 lh-160"> / {t('products.application.subscriptions.plan2.duration')}</small>
                        <h5 className="super-pack-padding fw-bold fs-16 lh-160 dark-text">{t('products.application.subscriptions.benefitTitle')} :</h5>
                        {/* 1 */}
                        <div className="super-pack-padding row mt-3">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                                {t('products.application.subscriptions.plan2.benefit1')}
                            </div>
                        </div>
                        {/* 2 */}
                        <div className="super-pack-padding row mt-3">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                                {t('products.application.subscriptions.plan2.benefit2')}
                            </div>
                        </div>
                        {/* 3 */}
                        <div className="super-pack-padding row mt-3">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                                {t('products.application.subscriptions.plan2.benefit3')}
                            </div>
                        </div>
                        {/* 4 */}
                        <div className="super-pack-padding row mt-3">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                                {t('products.application.subscriptions.plan2.benefit4')}
                            </div>
                        </div>
                        {/* 5 */}
                        <div className="super-pack-padding row mt-3">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                                {t('products.application.subscriptions.plan2.benefit5')}
                            </div>
                        </div>
                        {/* 6 */}
                        <div className="super-pack-padding row mt-3">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                                {t('products.application.subscriptions.plan2.benefit6')}
                            </div>
                        </div>
                        {/* 7 */}
                        <div className="super-pack-padding row mt-3">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                                {t('products.application.subscriptions.plan2.benefit7')}
                            </div>
                        </div>
                        {/* 8 */}
                        <div className="super-pack-padding row mt-2">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                                {t('products.application.subscriptions.plan2.benefit8')}
                            </div>
                        </div>
                        {/* Button */}
                        <div className="super-pack-padding">
                            <a className="text-decoration" href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" target="_blank" rel="noreferrer">
                                <button className="mx-auto d-block btnAppEurekaFill mt-5 py-2 text-white fw-bold">{t('products.application.subscriptions.choose')}</button>
                            </a>
                        </div>
                    </div>
                    {/* Basic Pack */}
                    <div className="basic-pack">
                    <h6 className="fw-bold fs-22 lh-160 dark-text text-center mb-4">{t('products.application.subscriptions.plan3.title')}</h6>
                        <div className="fw-bold line-through fs-16 lh-160 dark-text">{t('products.application.subscriptions.plan3.price')}</div>
                        <h5 className="text-orange fw-bold d-inline-block fs-30 lh-160 mb-4">{t('products.application.subscriptions.plan3.discountPrice')}</h5>
                        <small className="dark-text fw-bold fs-16 lh-160"> / {t('products.application.subscriptions.plan3.duration')}</small>
                        <h5 className="fw-bold fs-16 lh-160 dark-text">{t('products.application.subscriptions.benefitTitle')} :</h5>
                        {/* 1 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                                {t('products.application.subscriptions.plan3.benefit1')}
                            </div>
                        </div>
                        {/* 2 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                                {t('products.application.subscriptions.plan3.benefit2')}
                            </div>
                        </div>
                        {/* 3 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                                {t('products.application.subscriptions.plan3.benefit3')}
                            </div>
                        </div>
                        {/* 4 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                                {t('products.application.subscriptions.plan3.benefit4')}
                            </div>
                        </div>
                        {/* 5 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                                {t('products.application.subscriptions.plan3.benefit5')}
                            </div>
                        </div>
                        {/* 6 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                                {t('products.application.subscriptions.plan3.benefit6')}
                            </div>
                        </div>
                        {/* 7 */}
                        <div className="row mt-3">
                            <div className="col-lg-1 col-md-1 col-sm-1 col-1 me-2">
                                <BsCheckCircleFill size={20} style={{"color": "#FF9E20"}} />
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-10 col-10 text-grey fw-500 fs-14 lh-160">
                                {t('products.application.subscriptions.plan3.benefit7')}
                            </div>
                        </div>
                        {/* Button */}
                        <a className="text-decoration" href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" target="_blank" rel="noreferrer">
                            <button className="mx-auto d-block btnAppEureka mt-4 py-3 text-orange fw-bold">{t('products.application.subscriptions.choose')}</button>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Content3