import { Image } from 'react-bootstrap';
import LogoXpertPartner from "../../../assets/images/LogoXpertPartner.svg";

const XpertPartner = () => {
  return (
    <div className="text-center">
      <h5 className="fs-24 lh-30 fw-700 text-navy mt-5 mb-1">Our Partner</h5>
      <Image src={LogoXpertPartner} className="img-fluid mb-5"></Image>
    </div>
  )
}

export default XpertPartner;