import { Container, Row, Col } from 'react-bootstrap';

const Certification = () => {
  return(
    <>
      <h5 className="text-center fs-24 lh-30 fw-700 text-navy mb-5" style={{"margin-top": "100px"}}>Digital Talents & Skills Preparation and Certification</h5>
      <Container className="px-3 mt-5 pb-5">
      <Row className='xpertCertificationRow'>
        <Col className="xpertCertificationCol">Digital Marketing</Col>
        <Col className="xpertCertificationCol">Junior Web Developer</Col>
      </Row>
      <Row className='mt-1 xpertCertificationRow'>
        <Col className="xpertCertificationCol">Junior Mobile Developer</Col>
        <Col className="xpertCertificationCol">Data Scientist</Col>
      </Row>
      <Row className='mt-1 xpertCertificationRow'>
        <Col className="xpertCertificationCol">Cloud Computing Engineer</Col>
        <Col className="xpertCertificationCol">ICT Project Manager</Col>
      </Row>
      <Row className='mt-1 xpertCertificationRow'>
        <Col className="xpertCertificationCol">Data Analyst</Col>
        <Col className="xpertCertificationCol">Perekayasaan Aplikasi IOT</Col>
      </Row>
      <Row className='mt-1 xpertCertificationRow'>
        <Col className="xpertCertificationCol">Perekayasaan Platform IOT</Col>
        <Col className="xpertCertificationCol">Microsoft Office</Col>
      </Row>
      </Container>
    </>
  )
}

export default Certification