const About = () => {
  return (
    <>
      <h5 className='text-center fs-24 lh-30 fw-700 text-navy mt-5 mb-4'>About Eureka Edutainment</h5>
      <div className="xpertAbout">
        <div className="fw-500 fs-16 lh-160 text-grey">
          Eureka Edutainment Services provide comprehensive event services based on the needs of our partners by using our extensive knowledge and spacious experience in the world of event and entertainment.
        </div>
      <br />
      <br />
        <h5 className="dark-text fw-700 fs-20 lh-25">Our Objective</h5>
        <ul className="fw-500 fs-16 lh-160 dark-text mt-3 mb-5">
          <li className="mt-4">Create an event that uses digital technology as a foundation to achieve the event's objective for all segment</li>
          <li className="mt-4">Organize inspiring programs for savvy citizens</li>
          <li className="mt-4">Performing world-class quality education and entertainment (edutainment) programs</li>
          <li className="mt-4 pb-4">Providing innovative solutions as the client's top priority</li>
        </ul>
      </div>
    </>
  )
}

export default About;