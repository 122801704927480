import { Carousel, Container, Row, Image, Col } from 'react-bootstrap'
import BtnCarousel from '../../../assets/images/Carousel-btn.svg'
import EdutainmentEvent1 from '../../../assets/images/EdutainmentEvent1.svg'
import EdutainmentEvent2 from '../../../assets/images/EdutainmentEvent2.svg'
import EdutainmentEvent3 from '../../../assets/images/EdutainmentEvent3.svg'
import EdutainmentEvent4 from '../../../assets/images/EdutainmentEvent4.svg'
import EdutainmentEvent5 from '../../../assets/images/EdutainmentEvent5.svg'
import EdutainmentEvent6 from '../../../assets/images/EdutainmentEvent6.svg'


const Event = () => {
  const directionButtons = (direction) => {
    return (
      <span
        aria-hidden="true"
        className={direction === "Next" ? "button-next" : "button-prev"}
      >
        <img src={ BtnCarousel } alt="next" />
      </span>
    )
}

  return(
    <div>
      <h5 className='text-center fs-24 lh-30 fw-700 text-navy mt-5 mb-5'>Our Event</h5>
      <Carousel fade
                nextLabel={"Next"}
                prevLabel={"Previous"}
                nextIcon={directionButtons("Next")}
                prevIcon={directionButtons("Previous")}
                interval={2000}
                indicators={false}
                className='mb-5'>
                    {/* 1 */}
                    <Carousel.Item>
                    <Container>
                      <Row className='text-center'>
                        <Col>
                          <Image src={EdutainmentEvent1} className="img-fluid edutainmentEventImage" />
                        </Col>
                        <Col>
                          <Image src={EdutainmentEvent2} className="img-fluid edutainmentEventImage" />
                        </Col>
                        <Col>
                          <Image src={EdutainmentEvent3} className="img-fluid edutainmentEventImage" />
                        </Col>
                      </Row>
                      </Container>
                    </Carousel.Item>
                    {/* 2 */}
                    <Carousel.Item>
                    <Container>
                      <Row className='text-center'>
                        <Col>
                          <Image src={EdutainmentEvent4} className="img-fluid edutainmentEventImage" />
                        </Col>
                        <Col>
                          <Image src={EdutainmentEvent5} className="img-fluid edutainmentEventImage" />
                        </Col>
                        <Col>
                          <Image src={EdutainmentEvent6} className="img-fluid edutainmentEventImage" />
                        </Col>
                      </Row>
                      </Container>
                    </Carousel.Item>
                </Carousel>
    </div>
  )
}

export default Event;