import Content2Mobile from './Content-2-mobile'
import { Carousel } from 'react-bootstrap'
import BtnCarousel from '../../../assets/images/Carousel-btn.svg'
import App1 from '../../../assets/images/App1.svg'
import App2 from '../../../assets/images/App2.svg'
import App3 from '../../../assets/images/App3.svg'
import App4 from '../../../assets/images/App4.svg'
import App5 from '../../../assets/images/App5.svg'
import App6 from '../../../assets/images/App6.svg'
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../../helpers/screen-size'

const Content2 = () => {
    const { t } = useTranslation()

    const [ , width ] = useWindowSize()
    const isMobile = width < 1000

    const directionButtons = (direction) => {
        return (
          <span
            aria-hidden="true"
            className={direction === "Next" ? "" : "btn-rotate"}
          >
            <img src={ BtnCarousel } alt="next" />
          </span>
        )
    }

    if(isMobile) {
        return(
            <Content2Mobile />
        )
    } else {
        return (
            <div className="produkApp2">
                <h6 className="fw-bold fs-30 m-fs-16 lh-160 color-blue text-center text-lg-start text-md-start">{t('products.application.features.title')}</h6>
                <Carousel 
                    nextLabel={"Next"}
                    prevLabel={"Previous"}
                    nextIcon={directionButtons("Next")}
                    prevIcon={directionButtons("Previous")}
                    interval={2000}
                    indicators={false}>
                    {/* 1 */}
                    <Carousel.Item>
                        <div className='d-flex flex-row justify-content-center mt-5'>
                            {/* Card 1 */}
                            <div className='imageApp mx-4 col-3'>
                                <img src={ App1 } className="mx-auto d-block mt-4" alt="Eureka" /> <br />
                                <div className='text-center dark-text fs-16 lh-160 fw-bold dark-text'>{t('products.application.features.feature1')}</div> <br />
                            </div>
                            <div className='imageApp mx-4 col-3'>
                                <img src={ App2 } className="mx-auto d-block mt-4" alt="Eureka" /> <br />
                                <div className='text-center dark-text fs-16 lh-160 fw-bold dark-text'>{t('products.application.features.feature2')}</div> <br />
                            </div>
                            <div className='imageApp mx-4 col-3'>
                                <img src={ App3 } className="mx-auto d-block mt-4" alt="Eureka" /> <br />
                                <div className='text-center dark-text fs-16 lh-160 fw-bold dark-text'>{t('products.application.features.feature3')}</div> <br />
                            </div>
                        </div>
                    </Carousel.Item>
                    {/* 2 */}
                    <Carousel.Item>
                        <div className='d-flex flex-row justify-content-center mt-5'>
                            {/* Card 1 */}
                            <div className='imageApp mx-4 col-3'>
                                <img src={ App4 } className="mx-auto d-block mt-4" alt="Eureka" /> <br />
                                <div className='text-center dark-text fs-16 lh-160 fw-bold dark-text'>{t('products.application.features.feature4')}</div> <br />
                            </div>
                            <div className='imageApp mx-4 col-3'>
                                <img src={ App5 } className="mx-auto d-block mt-4" alt="Eureka" /> <br />
                                <div className='text-center dark-text fs-16 lh-160 fw-bold dark-text'>{t('products.application.features.feature5')}</div> <br />
                            </div>
                            <div className='imageApp mx-4 col-3'>
                                <img src={ App6 } className="mx-auto d-block mt-4" alt="Eureka" /> <br />
                                <div className='text-center dark-text fs-16 lh-160 fw-bold dark-text'>{t('products.application.features.feature6')}</div> <br />
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </div>
        )
    }
}

export default Content2