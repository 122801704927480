import { BsChevronDown } from "react-icons/bs"
import { BsChevronUp } from "react-icons/bs"
import { useState } from "react"
import { useTranslation } from 'react-i18next';

const Content4 = () => {

    const { t } = useTranslation()

    const dropDownText = {
        text1: false,
        text2: false,
        text3: false,
        text4: false,
        text5: false,
        text6: false,
        text7: false,
        text8: false
    }

    const [textShow, setTextShow] = useState(dropDownText)

    const showingText = (showPosition) => {
        setTextShow({...dropDownText, [showPosition]: true})
    }
    
    return(
        <div>
            <br />
            <h6 className="color-blue fw-bold fs-30 m-fs-16 text-center my-5">{t('landing.faqs.title')}</h6>
            <div className="d-flex justify-content-center">
                <div className="container-fluid">
                    {/* 1 */}
                    <div id="box-content4" className="row">
                        <div className="col-lg-12 col-12">
                            <div className="row pointer" onClick={() => showingText('text1')}>
                                <div className="col-lg-10 col-md-10 col-sm-10 col-11">
                                    <h6 className="fs-22 m-fs-12 fw-bold lh-160">{t('landing.faqs.question1.title')}</h6>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-1">
                                { textShow.text1 ? (
                                    <BsChevronUp className="dark-text" />
                                ) : (
                                    <BsChevronDown className="dark-text" />
                                )}
                                </div>
                            </div>
                            <div className={textShow.text1 ? 'dropdown-text fs-18 m-fs-10 lh-160 text-grey mt-4': 'd-none'}>
                                {t('landing.faqs.question1.description')}
                            </div>
                            <hr className="blue-line dropdown-text" />
                        </div>
                    </div>
                    {/* 2 */}
                    <div id="box-content4" className="row pointer" onClick={() => showingText('text2')}>
                        <div className="col-lg-12 col-12">
                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-10 col-11">
                                    <h6 className="fs-22 m-fs-12 fw-bold lh-160">{t('landing.faqs.question2.title')}</h6>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-1">
                                { textShow.text2 ? (
                                    <BsChevronUp className="dark-text" />
                                ) : (
                                    <BsChevronDown className="dark-text" />
                                )}
                                </div>
                            </div>
                            <p className={textShow.text2 ? 'dropdown-text fs-18 m-fs-10 lh-160 text-grey mt-4': 'd-none'}>
                                {t('landing.faqs.question2.description')}
                            </p>
                            <hr className="blue-line dropdown-text" />
                        </div>
                    </div>
                    {/* 3 */}
                    <div id="box-content4" className="row pointer" onClick={() => showingText('text3')}>
                        <div className="col-lg-12 col-12">
                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-10 col-11">
                                    <h6 className="fs-22 m-fs-12 fw-bold lh-160">{t('landing.faqs.question3.title')}</h6>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-1">
                                { textShow.text3 ? (
                                    <BsChevronUp className="dark-text" />
                                ) : (
                                    <BsChevronDown className="dark-text" />
                                )}
                                </div>
                            </div>
                            <p className={textShow.text3 ? 'dropdown-text fs-18 m-fs-10 lh-160 text-grey mt-4': 'd-none'}>
                                {t('landing.faqs.question3.description')}
                            </p>
                            <hr className="blue-line dropdown-text" />
                        </div>
                    </div>
                    {/* 4 */}
                    <div id="box-content4" className="row pointer" onClick={() => showingText('text4')}>
                        <div className="col-lg-12 col-12">
                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-10 col-11">
                                    <h6 className="fs-22 m-fs-12 fw-bold lh-160">{t('landing.faqs.question4.title')}</h6>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-1">
                                    { textShow.text4 ? (
                                    <BsChevronUp className="dark-text" />
                                ) : (
                                    <BsChevronDown className="dark-text" />
                                )}
                                </div>
                            </div>
                            <p className={textShow.text4 ? 'dropdown-text fs-18 m-fs-10 lh-160 text-grey mt-4': 'd-none'}>
                                {t('landing.faqs.question4.description')}
                            </p>
                            <hr className="blue-line dropdown-text" />
                        </div>
                    </div>
                    {/* 5 */}
                    <div id="box-content4" className="row pointer" onClick={() => showingText('text5')}>
                        <div className="col-lg-12 col-12">
                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-10 col-11">
                                    <h6 className="fs-22 m-fs-12 fw-bold lh-160">{t('landing.faqs.question5.title')}</h6>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-1">
                                    { textShow.text5 ? (
                                    <BsChevronUp className="dark-text" />
                                ) : (
                                    <BsChevronDown className="dark-text" />
                                )}
                                </div>
                            </div>
                            <div className={textShow.text5 ? 'dropdown-text fs-18 m-fs-10 lh-160 text-grey mt-4': 'd-none'}>
                                {t('landing.faqs.question5.description')}
                            </div>
                            <hr className="blue-line dropdown-text" />
                        </div>
                    </div>
                    {/* 6 */}
                    <div id="box-content4" className="row pointer" onClick={() => showingText('text6')}>
                        <div className="col-lg-12 col-12">
                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-10 col-11">
                                    <h6 className="fs-22 m-fs-12 fw-bold lh-160">{t('landing.faqs.question6.title')}</h6>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-1">
                                { textShow.text6 ? (
                                    <BsChevronUp className="dark-text" />
                                ) : (
                                    <BsChevronDown className="dark-text" />
                                )}
                                </div>
                            </div>
                            <div className={textShow.text6 ? 'dropdown-text fs-18 m-fs-10 lh-160 text-grey mt-4': 'd-none'}>
                                {t('landing.faqs.question6.description')}
                            </div>
                            <hr className="blue-line dropdown-text" />
                        </div>
                    </div>
                    {/* 7 */}
                    <div id="box-content4" className="row pointer" onClick={() => showingText('text7')}>
                        <div className="col-lg-12 col-12">
                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-10 col-11">
                                    <h6 className="fs-22 m-fs-12 fw-bold lh-160">{t('landing.faqs.question7.title')}</h6>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-1">
                                { textShow.text7 ? (
                                    <BsChevronUp className="dark-text" />
                                ) : (
                                    <BsChevronDown className="dark-text" />
                                )}
                                </div>
                            </div>
                            <p className={textShow.text7 ? 'dropdown-text fs-18 m-fs-10 lh-160 text-grey mt-4': 'd-none'}>
                                {t('landing.faqs.question7.description')}
                            </p>
                            <hr className="blue-line dropdown-text" />
                        </div>
                    </div>
                    {/* 8 */}
                    <div id="box-content4" className="row pointer mb-5" onClick={() => showingText('text8')}>
                        <div className="col-lg-12 col-12">
                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-10 col-11">
                                    <h6 className="fs-22 m-fs-12 fw-bold lh-160">{t('landing.faqs.question8.title')}</h6>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-1">
                                { textShow.text8 ? (
                                    <BsChevronUp className="dark-text" />
                                ) : (
                                    <BsChevronDown className="dark-text" />
                                )}
                                </div>
                            </div>
                            <p className={textShow.text8 ? 'dropdown-text fs-18 m-fs-10 lh-160 text-grey mt-4': 'd-none'}>
                                {t('landing.faqs.question8.description')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content4