import { useTranslation } from 'react-i18next';

const Content2 = () => {

    const { t } = useTranslation()

    return(
        <div className="content2">
            <h6 className="fw-bold fs-30 m-fs-16 color-blue text-center pt-5">{t('landing.reasons.title')}</h6>
                <div className="d-flex flex-column flex-md-row">
                    {/* Left Box */}
                    {/* First */}
                    <div className="col-lg-4 content2-box1">
                        <div className="box1 g-0 blue-border py-0 py-md-3 py-lg-3">
                            <h6 className="fs-22 m-fs-14 dark-text fw-bold mx-4 mt-3 lh-160">{t('landing.reasons.section1.title')}</h6>
                            <p className="text-grey mx-4 lh-160 m-fs-12">{t('landing.reasons.section1.description')}</p>
                        </div>
                        {/* Second */}
                        <div id="content2-box2" className="g-0 blue-border py-0 py-md-3 py-lg-3">
                            <h6 className="fs-22 m-fs-14 m-fs-12 dark-text fw-bold mx-4 mt-3 lh-160">{t('landing.reasons.section2.title')}</h6>
                            <p className="text-grey mx-4 m-fs-14 m-fs-12 lh-160 text-justify">{t('landing.reasons.section2.description')}</p>
                        </div>
                    </div>
                    {/* Right Box */}
                    {/* Third */}
                    <div className="col-lg-4 content2-box3">
                        <div className="box3 g-0 blue-border py-0 py-md-3 py-lg-3">
                            <h6 className="fs-22 m-fs-14 m-fs-12 dark-text fw-bold mx-4 mt-3 lh-160">{t('landing.reasons.section3.title')}</h6>
                            <p className="text-grey mx-4 m-fs-14 m-fs-12 lh-160">{t('landing.reasons.section3.description')}</p>
                        </div>
                        {/* Fourth */}
                        <div id="content2-box4" className="g-0 blue-border py-0 py-md-3 py-lg-3">
                            <h6 className="fs-22 m-fs-14 m-fs-12 dark-text fw-bold mx-4 mt-3 lh-160">{t('landing.reasons.section4.title')}</h6>
                            <p className="text-grey mx-4 m-fs-14 m-fs-12 lh-160">{t('landing.reasons.section4.description')}</p>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default Content2