import { Trans, useTranslation } from "react-i18next"

const Content3 = () => {
    const { t } = useTranslation()

    return(
        <div className="content3Terms mt-4">
            <h6 className="fw-bold fs-22 m-fs-16 lh-160">{t('terms.subtitle')}</h6>
            <div className="fs-16 m-fs-12 lh-160 fw-500 text-grey">
                <Trans
                    i18nKey="terms.desc.paragraph1"
                    components={[<strong></strong>]}
                />
                
                <br /> <br />
                
                <Trans
                    i18nKey="terms.desc.paragraph2"
                    components={[<strong></strong>]}
                />
                
                <br /> <br />

                <b className="m-fs-12">{t('terms.desc.paragraph3')}</b> <br /> <br />
                {/*  */}
                <b className="m-fs-12">{t('terms.requirements.title')}</b>

                {/* ================================================================= */}
                {/* <Trans i18nKey='list_map'>
                <ul i18nIsDynamicList>
                    {arr.map(dog => (<li>{dog}</li>))}
                </ul>
                </Trans> */}


                {/* ================================================================= */}

                <ol type="A" className="inline-block-list m-fs-12">
                    <li className="my-2 m-fs-12">{t('terms.requirements.list1.item1')}</li>
                    <li className="my-2 m-fs-12">{t('terms.requirements.list1.item2')}</li>
                    <li className="my-2 m-fs-12">{t('terms.requirements.list1.item3.content')}</li>
                    <ol type="i">
                        <li className="my-1 m-fs-12">{t('terms.requirements.list1.item3.list1')}</li>
                        <li className="my-1 m-fs-12">{t('terms.requirements.list1.item3.list2')}</li>
                        <li className="my-1 m-fs-12">{t('terms.requirements.list1.item3.list3')}</li>
                        {/* =============================== */}
                        <Trans
                            i18nKey="terms.requirements.list1.item3.list4"
                            components={[<li></li>]}>
                        </Trans>
                    </ol>
                    <li className="my-2 m-fs-12">{t('terms.requirements.list1.item4')}</li>
                    <li className="my-2 m-fs-12">{t('terms.requirements.list1.item5')}</li>
                </ol> 
                <br />
                {/*  */}
                <b className="m-fs-12">{t('terms.termsOfUse.title')}</b>
                <ol type="A" className="inline-block-list">
                    <li className="my-2 m-fs-12">{t('terms.termsOfUse.list.item1')}</li>
                    <li className="my-2 m-fs-12">{t('terms.termsOfUse.list.item2')}</li>
                    <li className="my-2 m-fs-12">{t('terms.termsOfUse.list.item3')}</li>
                    <li className="my-2 m-fs-12">{t('terms.termsOfUse.list.item4')}</li>
                </ol>
                <br />
                {/*  */}
                <b className="m-fs-12">{t('terms.features.title')}</b>
                <ol type="A" className="inline-block-list">
                    <li className="my-2 m-fs-12">{t('terms.features.list1.title')}</li>
                        <ol type="i">
                            <li className="my-1 m-fs-12">{t('terms.features.list1.item1')}</li>
                            <li className="my-1 m-fs-12">{t('terms.features.list1.item2')}</li>
                        </ol>
                    <li className="my-2 m-fs-12">{t('terms.features.list2.title')}</li>
                        <ol type="i">
                            <li className="my-1 m-fs-12">{t('terms.features.list2.item1')}</li>
                            <li className="my-1 m-fs-12">{t('terms.features.list2.item2')}</li>
                        </ol>
                    <li className="my-2 m-fs-12">{t('terms.features.list3.title')}</li>
                        <ol type="i">
                            <li className="my-1 m-fs-12">{t('terms.features.list3.item1')}</li>
                        </ol>
                </ol>
                <br />
                {/*  */}
                <b className="m-fs-12">{t('terms.liability.title')}</b>
                <ol type="A" className="inline-block-list">
                    <li className="my-2 m-fs-12">{t('terms.liability.item1')}</li>
                    <li className="my-2 m-fs-12">{t('terms.liability.item2')}</li>
                    <li className="my-2 m-fs-12">{t('terms.liability.item3')}</li>
                    <li className="my-2 m-fs-12">{t('terms.liability.item4')}</li>
                </ol>
                <br />
                {/*  */}
                <b className="m-fs-12">{t('terms.notification.title')}</b> <br />
                <Trans 
                    i18nKey='terms.notification.content'
                    components={[<strong></strong>]}
                ></Trans>
                <br />
                <br />
                {/*  */}
                <b className="m-fs-12">{t('terms.security.title')}</b> <br />
                    {t('terms.security.content')}
                <br />
                <br />
                {/*  */}
                <b className="m-fs-12">{t('terms.onesignal.title')}</b> <br />
                    {t('terms.onesignal.content')}
                <br />
                <br />
                {/*  */}
                <b className="m-fs-12">{t('terms.closing.title')}</b>
                <ol type="A" className="inline-block-list">
                    <li className="my-2 m-fs-12">{t('terms.closing.list.item1')}</li>
                    <li className="my-2 m-fs-12">{t('terms.closing.list.item2')}</li>
                    <li className="my-2 m-fs-12">{t('terms.closing.list.item3')}</li>
                    <li className="my-2 m-fs-12">{t('terms.closing.list.item4')}</li>
                    <li className="my-2 m-fs-12">{t('terms.closing.list.item5')}</li>
                </ol>
            </div>
        </div>
    )
}

export default Content3