import { BsCheckCircleFill } from "react-icons/bs";
import RecommendMobile from '../../../assets/images/Recommend-mobile.svg'
import { useTranslation } from 'react-i18next';

const Content3Mobile = () => {
    const { t } = useTranslation()
    return(
        <div>
            <h6 className="text-center color-blue fw-bold m-fs-16 lh-160 my-5">{t('products.application.subscriptions.title')}</h6>
            <div id="hide-scrollbar" className="d-flex nowrap pb-5" style={{"overflow": "auto", "width": "100%"}}>
                {/* Starter Pack */}
                <div className="starter-pack">
                    <h6 className="fw-bold fs-12 lh-160 dark-text text-center mb-3">{t('products.application.subscriptions.plan1.title')}</h6>
                    <div className="fw-bold line-through m-fs-10 lh-160 dark-text">{t('products.application.subscriptions.plan1.price')}</div>
                    <h5 className="text-orange fw-bold d-inline-block fs-20 lh-160 mb-3">{t('products.application.subscriptions.plan1.discountPrice')}</h5>
                    <small className="dark-text fw-bold m-fs-10 lh-160 me-5"> /{t('products.application.subscriptions.plan1.duration')}</small>
                    <h5 className="fw-bold m-fs-10 lh-160 dark-text">{t('products.application.subscriptions.benefitTitle')} :</h5>
                    {/* 1 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan1.benefit1')}
                        </div>
                    </div>
                    {/* 2 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan1.benefit2')}
                        </div>
                    </div>
                    {/* 3 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan1.benefit3')}
                        </div>
                    </div>
                    {/* 4 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan1.benefit4')}
                        </div>
                    </div>
                    {/* 5 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan1.benefit5')}
                        </div>
                    </div>
                    {/* 6 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan1.benefit6')}
                        </div>
                    </div>
                    <a className="text-decoration" href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" target="_blank" rel="noreferrer">
                        <button className="mx-auto d-block btnAppEureka mt-3 py-1 text-orange fw-bold m-fs-12">{t('products.application.subscriptions.choose')}</button>
                    </a>
                </div>
                {/* Super Pack */}
                <div className="super-pack">
                    <h6 className="fw-bold m-fs-12 lh-160 text-center mb-3 mt-2 text-white super-pack-padding">{t('products.application.subscriptions.plan2.title')}</h6>
                    {/* Image */}
                    <img src={ RecommendMobile } alt='Eureka' className="mt-2 mb-3" />
                    <div className="super-pack-padding fw-bold line-through m-fs-10 lh-160 dark-text">{t('products.application.subscriptions.plan2.price')}</div>
                    <h5 className="super-pack-padding text-orange fw-bold d-inline-block m-fs-20 lh-160 mb-3">{t('products.application.subscriptions.plan2.discountPrice')}</h5>
                    <small className="red-text fw-bold m-fs-10 lh-160 me-5">/ {t('products.application.subscriptions.plan2.duration')}</small>
                    <h5 className="super-pack-padding fw-bold m-fs-10 lh-160 dark-text">{t('products.application.subscriptions.benefitTitle')} :</h5>
                    {/* 1 */}
                    <div className="super-pack-padding row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan2.benefit1')}
                        </div>
                    </div>
                    {/* 2 */}
                    <div className="super-pack-padding row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan2.benefit2')}
                        </div>
                    </div>
                    {/* 3 */}
                    <div className="super-pack-padding row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan2.benefit3')}                        
                        </div>
                    </div>
                    {/* 4 */}
                    <div className="super-pack-padding row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan2.benefit4')}                        
                        </div>
                    </div>
                    {/* 5 */}
                    <div className="super-pack-padding row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan2.benefit5')}                        
                        </div>
                    </div>
                    {/* 6 */}
                    <div className="super-pack-padding row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan2.benefit6')}                        
                        </div>
                    </div>
                    {/* 7 */}
                    <div className="super-pack-padding row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan2.benefit7')}                        
                        </div>
                    </div>
                    {/* 8 */}
                    <div className="super-pack-padding row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan2.benefit8')}                        
                        </div>
                    </div>
                    {/* Button */}
                    <div className="super-pack-padding">
                        <a className="text-decoration" href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" target="_blank" rel="noreferrer">
                            <button className="mx-auto d-block btnAppEurekaFill mt-3 py-1 text-white fw-bold m-fs-12">{t('products.application.subscriptions.choose')}</button>
                        </a>
                    </div>
                </div>
                {/* Basic Pack */}
                <div className="basic-pack">
                    <h6 className="fw-bold m-fs-12 lh-160 dark-text text-center mb-3">{t('products.application.subscriptions.plan3.title')}</h6>
                    <div className="fw-bold line-through m-fs-10 lh-160 dark-text">{t('products.application.subscriptions.plan3.price')}</div>
                    <h5 className="text-orange fw-bold d-inline-block fs-20 lh-160 mb-3">{t('products.application.subscriptions.plan3.discountPrice')}</h5>
                    <small className="dark-text fw-bold m-fs-10 lh-160 me-5"> / {t('products.application.subscriptions.plan3.duration')}</small>
                    <h5 className="fw-bold m-fs-10 lh-160 dark-text">{t('products.application.subscriptions.benefitTitle')} :</h5>
                    {/* 1 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan3.benefit1')}
                        </div>
                    </div>
                    {/* 2 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan3.benefit2')}
                        </div>
                    </div>
                    {/* 3 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan3.benefit3')}                        
                        </div>
                    </div>
                    {/* 4 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan3.benefit4')}                        
                        </div>
                    </div>
                    {/* 5 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan3.benefit5')}                        
                        </div>
                    </div>
                    {/* 6 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan3.benefit6')}                        
                        </div>
                    </div>
                    {/* 7 */}
                    <div className="row">
                        <div className="col-lg-1 col-1">
                            <BsCheckCircleFill size={12} style={{"color": "#FF9E20"}} />
                        </div>
                        <div className="col-lg-10 col-10 text-grey fw-500 m-fs-10 lh-160 mt-1 wrap">
                            {t('products.application.subscriptions.plan3.benefit7')}                        
                        </div>
                    </div>
                    {/* Button */}
                    <a className="text-decoration" href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" target="_blank" rel="noreferrer">
                        <button className="mx-auto d-block btnAppEureka mt-3 py-2 text-orange fw-bold m-fs-12">{t('products.application.subscriptions.choose')}</button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Content3Mobile