import { Container, Row, Col, Image } from 'react-bootstrap';
import ImagePartnerEdutainment from "../../../assets/images/EdutainmentPartner.svg"
import ImageCommunityEdutainment from "../../../assets/images/EdutainmentCommunity.svg"

const Partner = () => {
  return(
    <div>
      <Container className="px-3 mt-5 pb-5">
      <Row className='text-center'>
        <Col className="edutainmentPartnerCol">
          <h5 className='mt-4 mb-4 text-navy fw-700 fs-24 lh-30'>Our Partner</h5>
          <Image src={ImagePartnerEdutainment} className="img-fluid" />
        </Col>
        <Col className="edutainmentPartnerCol">
        <h5 className='mt-4 mb-4 text-navy fw-700 fs-24 lh-30'>Community Partner</h5>
        <Image src={ImageCommunityEdutainment} className="img-fluid" />
        </Col>
      </Row>
      </Container>
    </div>
  )
}

export default Partner;