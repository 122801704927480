import { Link } from "react-router-dom";
import { BsFillCaretRightFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Content2 = () => {
    const { t } = useTranslation()

    return(
        <div className="content2Terms mt-3">
            <Link to='/' className="text-decoration">
                <h6 className="d-inline-block fw-500 fs-14 m-fs-12 lh-160 text-grey linkHome">{t('topmenu.home')}</h6>
            </Link>
            <BsFillCaretRightFill className="text-grey mx-2" size={ 12 } />
            <h6 className="d-inline-block fw-500 fs-14 m-fs-12 lh-160 blue-text">{t('terms.title')}</h6>
        </div>
    )
}

export default Content2