import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";
import '../../assets/styles/styles.css'
import EurekaLogo from '../../assets/images/LogoEureka.png'
import ProductApp from '../../assets/images/ProductApp.svg'
// import ProductBook from '../../assets/images/ProductBook.svg'
import ProductXpert from '../../assets/images/ProductXpert.svg'
import ProductEdutainment from '../../assets/images/ProductEdutainment.svg'
import ProductBiz from '../../assets/images/ProductBiz.svg'
import { BsCaretDownFill } from "react-icons/bs";
// import { BsCaretUpFill } from "react-icons/bs";
// import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Header = () => {
  // const [ arrow, setArrow ] = useState(false)
  
  // const changeArrow = () => {
  //   setArrow(true)
  // }

  const { t } = useTranslation()

  return (
    <div className='vw-100 d-flex justify-content-center'>
      <Navbar className='ms-2 ms-lg-0 ms-md-0' expand="lg">
      {/* hidden only on xs */}
      <Navbar.Brand className='d-none d-sm-block' as={ Link } to="/"> 
          <img src={EurekaLogo} alt='Eureka' className='logo'></img>
      </Navbar.Brand>
      {/*  */}
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      {/* visible only on xs */}
      <Navbar.Brand className='d-block d-sm-none' as={ Link } to="/">
          <img src={EurekaLogo} alt='Eureka' className='logo'></img>
      </Navbar.Brand>
      {/*  */}
      <a id='btn-app' href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" className="btn d-block d-sm-none" role="button" aria-pressed="true" target="_blank" rel="noreferrer">Download Aplikasi</a>
      {/*  */}
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          {/* Home */}
          <NavLink to='/' className={({isActive}) => (isActive ? 'isActive' : 'notActive')}>{t('topmenu.home')}</NavLink>
          {/* About */}
          <NavLink to='/about' className={({isActive}) => (isActive ? 'isActive' : 'notActive')}>{t('topmenu.about')}</NavLink>
          {/* Dropdown */}
          <NavDropdown
          // onClick={changeArrow}
          title={<div className='m-fw-bold m-fs-14' style={{"display": "inline-block"}}>{t('topmenu.products.title')} <BsCaretDownFill /></div>}
          // title={arrow ? <div style={{display: "inline-block"}}>Produk <BsCaretUpFill /></div> : <div style={{display: "inline-block"}}>Produk <BsCaretDownFill /></div>}
          id='link'
          style={{"whiteSpace": "nowrap"}}>
            {/* App */}
            <div id='dropdown-item'>
              <NavLink to='/product/android-app' className={({isActive}) => (isActive ? 'isActive' : 'notActive')}>
                <div className="container-fluid headerNavbar">
                  <div className="row">
                    <div className="col-lg-3 col-3">
                      <img src={ProductApp} alt='Eureka' className='logoProduct ms-0 ms-lg-2 ms-md-2'></img>
                    </div>
                    <div className="col-lg-9 col-9">
                      <h5 className='fw-bold lh-160 fs-14'>{t('topmenu.products.application.title')}</h5>
                      <p className='fs-12 lh-160 fw-500 wrap'>{t('topmenu.products.application.description')}</p>
                      <u className='color-blue fw-bold lh-160 fw-600 fs-12'>{t('topmenu.viewMore')}</u>
                    </div>
                  </div>
                </div>
              </NavLink>
            <hr />
            {/* Product */}
              {/* <NavLink to='/product/book' className={({isActive}) => (isActive ? 'isActive' : 'notActive')}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-3 col-3">
                      <img src={ProductBook} alt='Eureka' className='logoProduct ms-0 ms-lg-2 ms-md-2'></img>
                    </div>
                    <div className="col-lg-9 col-9">
                      <h5 className='fw-bold lh-160 fs-14'>{t('topmenu.products.book.title')}</h5>
                      <p className='fs-12 lh-160 fw-500 wrap'>{t('topmenu.products.book.description')}</p>
                      <u className='color-blue fw-bold lh-160 fw-600 fs-12'>{t('topmenu.viewMore')}</u>
                    </div>
                  </div>
                </div>
              </NavLink> */}
              {/* Xpert */}
              <NavLink to='/product/xpert' className={({isActive}) => (isActive ? 'isActive' : 'notActive')}>
                <div className="container-fluid headerNavbar">
                  <div className="row">
                    <div className="col-lg-3 col-3">
                      <img src={ProductXpert} alt='Eureka' className='logoProduct ms-0 ms-lg-2 ms-md-2'></img>
                    </div>
                    <div className="col-lg-9 col-9">
                      <h5 className='fw-bold lh-160 fs-14'>Eureka Xpert</h5>
                      <p className='fs-12 lh-160 fw-500 wrap'>Offers professional skills and talent development for corporates, startups...</p>
                      <u className='color-blue fw-bold lh-160 fw-600 fs-12'>{t('topmenu.viewMore')}</u>
                    </div>
                  </div>
                </div>
              </NavLink>
              <hr />
              {/* Edutainment */}
              <NavLink to='/product/edutainment' className={({isActive}) => (isActive ? 'isActive' : 'notActive')}>
                <div className="container-fluid headerNavbar">
                  <div className="row">
                    <div className="col-lg-3 col-3">
                      <img src={ProductEdutainment} alt='Eureka' className='logoProduct ms-0 ms-lg-2 ms-md-2'></img>
                    </div>
                    <div className="col-lg-9 col-9">
                      <h5 className='fw-bold lh-160 fs-14'>Eureka Edutainment</h5>
                      <p className='fs-12 lh-160 fw-500 wrap'>Provide comprehensive event services based on the needs of our partners.</p>
                      <u className='color-blue fw-bold lh-160 fw-600 fs-12'>{t('topmenu.viewMore')}</u>
                    </div>
                  </div>
                </div>
              </NavLink>
              <hr />
              {/* Biz */}
              <NavLink to='/product/biz' className={({isActive}) => (isActive ? 'isActive' : 'notActive')}>
                <div className="container-fluid headerNavbar mb-4">
                  <div className="row">
                    <div className="col-lg-3 col-3">
                      <img src={ProductBiz} alt='Eureka' className='logoProduct ms-0 ms-lg-2 ms-md-2'></img>
                    </div>
                    <div className="col-lg-9 col-9">
                      <h5 className='fw-bold lh-160 fs-14'>Eureka Biz</h5>
                      <p className='fs-12 lh-160 fw-500 wrap'>Helps corporates to develop their business and contributes to improving the...</p>
                      <u className='color-blue fw-bold lh-160 fw-600 fs-12'>{t('topmenu.viewMore')}</u>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          </NavDropdown>
          {/* Blog */}
          <a id='link' className='mt-0 mt-lg-2 mt-md-2' href="https://medium.com/eureka-edutech" target="_blank" rel="noreferrer">{t('topmenu.blog')}</a>
          <a id='btn-app' href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" className="btn d-none d-sm-block" role="button" aria-pressed="true" target="_blank" rel="noreferrer">{t('topmenu.download')}</a>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    </div>
  )
}

export default Header;