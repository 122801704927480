import './App.css';

import { BrowserRouter } from "react-router-dom";
import Router from './routes/index'

// import internationalization
import './i18n';

function App() {
  // Clear local storage when window closed / reloaded
  window.onbeforeunload = function() {
    localStorage.clear()
 }

  return (
    <div className='App'>
      <BrowserRouter>{/* 41.20 */}
        <Router/>
      </BrowserRouter>
    </div>
  );

  // function useWindowSize () {
  //   const [size, setSize] = useState([window.innerHeight, window.innerWidth])
  //   useEffect(() => {
  //     const handleResize = () => {
  //       setSize([window.innerHeight, window.innerWidth])
  //     }
  //     window.addEventListener("resize", handleResize)
  //     return () => {
  //       window.removeEventListener("resize", handleResize)
  //     }
  //   }, [])
  //   return size
  // }
}

export default App;
