import { Col } from 'react-bootstrap';
import '../../assets/styles/styles.css'
import GooglePlay from '../../assets/images/GooglePlay.svg'
import { useTranslation } from 'react-i18next';

const Banner = () => {

    const { t } = useTranslation()

    return (
        <Col className='banner vw-100'>
            <h1 className='fw-bold fs-42 white-text m-fs-24'>{t('landing.appName')}</h1>
            <h5 className='fs-22 m-fs-16 white-text mt-4 col-lg-6 col-md-6 col-sm-12 col-12 wrap'>
                {t('landing.description')}
            </h5>
            <a className="text-decoration d-none d-sm-block" href="https://play.google.com/store/apps/details?id=com.eureka.eureka_kt" target="_blank" rel="noreferrer">
                <object className="mt-3 logoGooglePlayBanner pointer-events" type="image/svg+xml" aria-label="logo" data={ GooglePlay }></object>
            </a>
        </Col>
    )
}

export default Banner;