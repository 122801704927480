import { Container, Row, Col } from 'react-bootstrap';

const Banner = () => {
  return (
    <div className="bannerProductEdutainment mt-3">
      <Container>
        <Row className='mt-5'>
          <Col xs={6} className='mt-1'>
            <h5 className='fw-700 fs-52 lh-120 dark-text'>EUREKA</h5>
            <h5 className='fw-700 fs-52 lh-120 text-orange'>EDUTAINMENT</h5>
            <div className='text-grey fw-500 fs-24 lh-160'>
              provide comprehensive event services based on the needs of our partners.
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner;