import { useEffect } from 'react';
import Footer from '../../../components/Footer';
import Header from "../../../components/Header";
import Banner from './Banner';
import XpertPartner from './Partner';
import XpertAbout from './About';
import XpertTopic from './Topic';
import Certification from './Certification';

const Xpert = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='overflow-x-hidden'>
      <Header />

      <Banner />
      <XpertPartner />
      <XpertAbout />
      <XpertTopic />
      <Certification />

      <Footer />
    </div>
  )
}

export default Xpert;