import '../../assets/styles/styles.css'
import EurekaLogo from '../../assets/images/LogoEureka.png'
import { useTranslation } from 'react-i18next';

const Description = () => {
    const { t } = useTranslation()

    return (
        <div>
            <div className="gradient text-center white-text fs-30 m-fs-18 fw-bold">{t('about.title')}</div>
            <img src={EurekaLogo} alt='Eureka' className='logoAbout mx-auto d-block my-4'></img>
            <div id='description' className='lh-160 text-grey m-fs-12'>
                <b>PT. Edukasi Rekanan Anda (Eureka Edutech)</b>{t('about.descriptions.description1')}<br /> <br />
                {t('about.descriptions.description2')} <br /> <br />
                {t('about.descriptions.description3')} <br /> <br />
                {t('about.descriptions.description4')} <br />
            <ol>
                <li className='lh-160 m-fs-12'>{t('about.descriptions.description5')}</li>
                <li className='lh-160 m-fs-12'>{t('about.descriptions.description6')}</li>
                <li className='lh-160 m-fs-12'>{t('about.descriptions.description7')}</li>
            </ol>
                {t('about.descriptions.description8')}
            </div>
        </div>
    )
}

export default Description