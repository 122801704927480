import { useEffect } from 'react';
import Footer from '../../components/Footer';
import Header from "../../components/Header";
import Content1 from './Content-1';
import Content2 from './Content-2';
import Content3 from './Content-3';

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='overflow-x-hidden'>
      <Header />
      <Content1 />
      <Content2 />
      <Content3 />

      <br /> <br /> 
      <br className='d-none d-sm-block' /> <br className='d-none d-sm-block' />

      <Footer />
    </div>
  )
}

export default Terms